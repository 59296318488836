.row {
  width: 100%;

  &:before,
  &:after {
    content: '';
    display: block;
    clear: both;
    width: 0;
  }
}

@mixin columnStyles($breakpointPrefix, $breakpointWidth) {
  $columns: one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve;
  @each $column in $columns {
    $i: index($columns, $column);

    &.#{$breakpointPrefix}#{$column} {
      width: percentage($i/length($columns));
    }
    &.#{$breakpointPrefix}push-#{$column} {
      margin-left: percentage($i/length($columns));
    }
    &.#{$breakpointPrefix}pull-#{$column} {
      margin-right: percentage($i/length($columns));
    }
  }

  $fixedWidthIncrement: 50;
  $fixedWidth: $fixedWidthIncrement;
  @while $fixedWidth < $breakpointWidth {
    &.#{$breakpointPrefix}fixed-#{$fixedWidth} {
      width: #{$fixedWidth}px;
      flex-shrink: 0;
      max-width: 100%;
    }
    $fixedWidth: $fixedWidth + $fixedWidthIncrement;
  }
  &.#{$breakpointPrefix}fill {
    float: none;
    width: auto;
    overflow: hidden; //prevent wrapping underneath
    flex-grow: 1;
  }

  &.#{$breakpointPrefix}push-none {
    margin-left: 0;
  }
  &.#{$breakpointPrefix}pull-none {
    margin-right: 0;
  }

  &.#{$breakpointPrefix}float-right {
    float: right;
  }
}

.column {
  float: left;

  @each $breakpointKey, $breakpointWidth in $mainBreakpoints {
    // loop through and add columnStyles for each breakpoint
    @if (isFirstInMap(map-keys($mainBreakpoints), $breakpointKey)) {
      @include columnStyles(
        null,
        $breakpointWidth
      ); // don't wrap the first (default) style set in a media query
    } @else {
      @media (min-width: #{$breakpointWidth}px) {
        @include columnStyles($breakpointKey + '-', $breakpointWidth);
      }
    }
  }
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.height-fill {
  height: 100%;
}
