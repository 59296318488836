.bg- {
  &white {
    background-color: getColor('white') !important;
    color: getColor('copy-gray');
  }

  &light-gray {
    background: getColor('gray-lt') !important;
  }

  &gray {
    background: getColor('gray') !important;
  }

  &dark-gray {
    background: getColor('gray-dk') !important;
  }

  &blue {
    background-color: getColor('brand-blue') !important;
    color: getColor('white');
  }

  &red {
    background: getColor('brand-red') !important;
    color: getColor('white');
  }

  &transparent {
    background: transparent !important;
  }
}
