.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  text-transform: none; // For use as a span within icon buttons <button ds-button><span class="icon-help"></span><span class="sr-only">Show help text</span></button
}

// Use only when we're adding tabindex to an element that
// isn't typically in the tabindex, like a span.
.focus {
  &-on {
    // Enable browser default focus for a11y
    outline: auto 5px Highlight;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &-off {
    // Disable browser default focus for a11y
    outline: none;
  }
}
