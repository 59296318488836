@import '../style/colors';

$modalZIndex: 7000;

.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: -#{$modalZIndex};
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    z-index 0s linear 0.2s;

  > .content {
    margin: 120px 7% 7%;
    min-width: 300px;
    color: getColor('copy-gray');
    background-color: getColor('white');
    outline: 0; //for focus on chrome
    position: relative;

    .modal-title {
      position: absolute;
      color: #ffffff;
      margin: 0;
      left: 0;
      top: -1.4em;
      width: calc(100% - 1em);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .icon-exit.close {
      position: absolute;
      top: -1.5em;
      right: 0;
      z-index: 99;
      padding: 0.5em;
      margin: -0.5em -0.5em 0 0;
      background: none;
      font-size: 1.3em;
      color: getColor('white');
    }
  }
}

body.modal-open {
  overflow: hidden;

  @at-root #body-wrapper {
    transition: filter 0.2s ease-in-out;
    -webkit-transition: -webkit-filter 0.2s ease-in-out;
    -moz-transition: -moz-filter 0.2s ease-in-out;
    -ms-transition: -ms-filter 0.2s ease-in-out;
    -o-transition: -o-filter 0.2s ease-in-out;
  }

  #body-wrapper {
    filter: blur(2px);
  }

  .modal {
    z-index: $modalZIndex;
    opacity: 1;
    transition:
      opacity 0.2s ease-in-out,
      z-index 0s linear 0s;
  }
}
