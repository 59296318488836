/*
 Work in progress - See Jesse Clark with questions
*/

@import '@amfament/ds-tokens-icons-scss/token-icon-map.scss';
@import 'token-colors';

$fontIcons: 'icomoon';
@font-face {
  font-family: $fontIcons;
  src: url('~@amfament/ds-icons-icofont/icomoon.eot?20220127');
  src:
    url('~@amfament/ds-icons-icofont/icomoon.eot?20220127#iefix') format('embedded-opentype'),
    url('~@amfament/ds-icons-icofont/icomoon.ttf?20220127') format('truetype'),
    url('~@amfament/ds-icons-icofont/icomoon.woff?20220127') format('woff'),
    url('~@amfament/ds-icons-icofont/icomoon.svg?20220127#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*='icon-'],
[class^='icon-'],
.pseudo-icon {
  line-height: 1;

  &:before,
  &:after {
    font: {
      family: $fontIcons;
      style: normal;
      weight: normal;
      variant: normal;
    }
    text-transform: none;
  }

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.mydp {
  [class*='icon-'],
  [class^='icon-'],
  .pseudo-icon {
    line-height: 1;
    color: getNewTokenColor('neutral', '600');
    &:before,
    &:after {
      font: {
        family: 'mydatepicker';
        style: normal;
        weight: normal;
        variant: normal;
      }
      text-transform: none;
    }

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }

  span.icon-mydpremove:before {
    font-family: $fontIcons;
    content: '\f132';
  }

  span.icon-mydpcalendar:before {
    font-family: $fontIcons;
    content: '\e912';
  }

  span.icon-mydptoday:before {
    content: '';
  }
}

@mixin init-icon-classes {
  @each $name, $char in $token-icon-map {
    // Setup a class name for each icon
    .icon-#{$name}:before {
      content: $char;
    }
  }
}

[class*='icon-'].validation-green-text,
[class^='icon-'].validation-green-text,
.icon.validation-green-text {
  color: getNewTokenColor('success', '400') !important;
}
