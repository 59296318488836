@import './breakpoints';
@import 'layout/flex';

// Stubbed out for breakpoint driven flex: options
// @todo - jjc - add breakpoints for flex-N

@mixin init-flex-classes() {
  .flex-auto {
    flex: 1 1 auto;
  }
  .flex-constrained {
    flex: 0 0 auto;
  }
  .flex-grow-only {
    flex: 1 0 auto;
  }
  .flex-shrink-only {
    flex: 0 1 auto;
  }
  .flex-min-0 {
    min-width: 0;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }

  // Overriding this from the digital style guide
  .spaced-grow-wrap {
    margin: 0;
  }

  // flex direction with breakpoints
  @each $breakpointKey, $breakpointValue in $mainBreakpoints {
    @if (isFirstInList(map-keys($mainBreakpoints), $breakpointKey)) {
      @include breakpointFlexDirection(null);
    } @else {
      @media (min-width: #{$breakpointValue}px) {
        @include breakpointFlexDirection($breakpointKey + '-');
      }
    }
  }
}

@mixin breakpointFlexDirection($breakpointPrefix) {
  @each $directionContentValue in column, column-reverse, row, row-reverse, unset {
    .#{$breakpointPrefix}flex-direction-#{$directionContentValue} {
      flex-direction: $directionContentValue;
    }
  }
}
