@import '~@angular/cdk/overlay-prebuilt.css';
@import 'app-styles';

/* replacing viewEncapsulation:none */
@import './app/policy/shared/policy-large';
@import './app/policy/proof-of-insurance/proof-of-insurance';
@import './app/policy/containers/plchangerequest/plchangerequest.component';

// Hide Feedback tab (shows for AFI customers)
#oo_tab {
  display: none !important;
}

// Hide ReCaptcha Badge throughout MyAccount
.grecaptcha-badge {
  display: none !important;
}

html,
body {
  height: 100%;
}

.mat-calendar-body-cell {
  &:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
}

.with-gradient {
  &::before {
    background: linear-gradient(
      135deg,
      getNewTokenColor('primary', '400'),
      getNewTokenColor('primary', '800')
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.2rem;
  }
}

.with-gradient-text {
  background: linear-gradient(180deg, #4f90ff 22.4%, #003da5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}
.pilet {
  display: flex;
  width: 78px;
  height: 28px;
  gap: 6px;
  position: relative;
  border: 1px getNewTokenColor('success', '200') solid;
  border-radius: 15px;
  background: getNewTokenColor('success', '50');
}
.pilet-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: getNewTokenColor('success', '800');
  font-size: 14px;
  font-weight: 700;
  gap: 3px;
  word-wrap: break-word;
  text-align: center;
}
