.tiles {
  display: flex;
  flex-wrap: wrap;

  > .column {
    padding: 25px 10px;
    text-align: center;
    border-bottom: 1px solid getColor('gray');
  }

  &.borderless > .column {
    border-bottom: 0;
  }

  .accent {
    [class*='icon-'] {
      font-size: 0.8em;
    }
  }
}
