@import './breakpoints';
@import './token-colors';

/* ===================================
Purpose:
    To provide helper placeholders that can be @extended by classes.
    To @extend a .class, you have to include all of the css in the file
    Placeholder markup doesn't create css.
    See: http://vanseodesign.com/css/sass-placeholders/

    Note: If you're within a @mediaQuery, you can only @extend from within the same mediaQuery
    Here's what not to do:

    .cant-extend-me {
        background-color: red;
    }

    @media print {
        .seriousError {
            // INVALID EXTEND: .error is used outside of the "@media print" directive
            @extend .cant-extend-me;
            border-width: 3px;
        }
    }

=================================== */

$defaultOffset: 15;
$offsetValues: 80, 60, 50, 40, 30, 20, 16, 12, 10, 8, 6, 5, 4, 2, 0;
$offsetSides: top, right, bottom, left;

///* Functions *///
@function isFirstInList($listToCheck, $listValue) {
  //$mapData should contain ($key $value)
  @return nth($listToCheck, 1) == $listValue; //return boolean for if the arg data is first in the map
}

/* ===================================
Breakpoint Placeholders
This mixin creates breakpoint prefixed and unprefixed placeholders
=================================== */
@mixin breakpointStyles($breakpointPrefix) {
  @each $paddingValue in $offsetValues {
    .#{$breakpointPrefix}pad-horizontal-#{$paddingValue} {
      padding-left: #{$paddingValue}px !important;
      padding-right: #{$paddingValue}px !important;
    }
  }

  @each $paddingValue in $offsetValues {
    .#{$breakpointPrefix}pad-vertical-#{$paddingValue} {
      padding-top: #{$paddingValue}px !important;
      padding-bottom: #{$paddingValue}px !important;
    }
  }

  /* ===================================
    Padding Classes (Duplicated from digital styleguide to add additional offsetValues
    =================================== */

  .#{$breakpointPrefix}pad {
    padding: #{$defaultOffset}px !important;
  }
  @each $paddingSide in $offsetSides {
    .#{$breakpointPrefix}pad-#{$paddingSide} {
      padding-#{$paddingSide}: #{$defaultOffset}px !important;
    }
  }
  @each $paddingValue in $offsetValues {
    .#{$breakpointPrefix}pad-#{$paddingValue} {
      padding: if($paddingValue == 0, #{$paddingValue}px !important, #{$paddingValue}px !important);
    }
  }
  @each $paddingValue in $offsetValues {
    @each $paddingSide in $offsetSides {
      .#{$breakpointPrefix}pad-#{$paddingSide}-#{$paddingValue} {
        padding-#{$paddingSide}: if(
          $paddingValue == 0,
          #{$paddingValue}px !important,
          #{$paddingValue}px !important
        );
      }
    }
  }

  /* ===================================
    Padding Classes to offset left+right or top+bottom
    =================================== */

  @each $paddingValue in $offsetValues {
    .#{$breakpointPrefix}pad-horizontal-#{$paddingValue} {
      padding-left: #{$paddingValue}px !important;
      padding-right: #{$paddingValue}px !important;
    }
  }

  @each $paddingValue in $offsetValues {
    .#{$breakpointPrefix}pad-vertical-#{$paddingValue} {
      padding-top: #{$paddingValue}px !important;
      padding-bottom: #{$paddingValue}px !important;
    }
  }

  /* ===================================
    Margin Classes (Duplicated from digital styleguide to add additional offsetValues
    =================================== */

  .#{$breakpointPrefix}margin {
    margin: #{$defaultOffset}px !important;
  }
  @each $marginSide in $offsetSides {
    .#{$breakpointPrefix}margin-#{$marginSide} {
      margin-#{$marginSide}: #{$defaultOffset}px !important;
    }
  }
  @each $marginValue in $offsetValues {
    .#{$breakpointPrefix}margin-#{$marginValue} {
      margin: if($marginValue == 0, #{$marginValue}px !important, #{$marginValue}px !important);
    }
  }
  @each $marginValue in $offsetValues {
    @each $marginSide in $offsetSides {
      .#{$breakpointPrefix}margin-#{$marginSide}-#{$marginValue} {
        margin-#{$marginSide}: if(
          $marginValue == 0,
          #{$marginValue}px !important,
          #{$marginValue}px !important
        );
      }
    }
  }

  /* ===================================
    Margin Classes to offset left+right or top+bottom
    =================================== */

  @each $marginValue in $offsetValues {
    .#{$breakpointPrefix}margin-horizontal-#{$marginValue} {
      margin-left: if($marginValue == 0, 0 0 0 0, #{$marginValue}px !important);
      margin-right: if($marginValue == 0, 0 0 0 0, #{$marginValue}px !important);
    }
  }

  @each $marginValue in $offsetValues {
    .#{$breakpointPrefix}margin-vertical-#{$marginValue} {
      margin-top: if($marginValue == 0, 0 0 0 0, #{$marginValue}px !important);
      margin-bottom: if($marginValue == 0, 0 0 0 0, #{$marginValue}px !important);
    }
  }
}

/* ===================================
Breakpoint Placeholders
This mixin creates breakpoint prefixed and unprefixed placeholders
=================================== */
@mixin breakpointPlaceholders($breakpointPrefix) {
  @each $textAlignValue in left, right, center, justify {
    %#{$breakpointPrefix}text-#{$textAlignValue} {
      text-align: $textAlignValue;
    }
  }

  /* ===================================
    Margin Placeholders
    =================================== */
  %#{$breakpointPrefix}margin {
    margin: #{$defaultOffset}px !important;
  }
  @each $marginSide in $offsetSides {
    %#{$breakpointPrefix}margin-#{$marginSide} {
      margin-#{$marginSide}: #{$defaultOffset}px !important;
    }
  }
  @each $marginValue in $offsetValues {
    %#{$breakpointPrefix}margin-#{$marginValue} {
      margin: if($marginValue == 0, #{$marginValue}px !important, #{$marginValue}px !important);
    }
  }
  @each $marginValue in $offsetValues {
    @each $marginSide in $offsetSides {
      %#{$breakpointPrefix}margin-#{$marginSide}-#{$marginValue} {
        margin-#{$marginSide}: if(
          $marginValue == 0,
          #{$marginValue}px !important,
          #{$marginValue}px !important
        );
      }
    }
  }

  /* ===================================
    Padding Placeholders
    =================================== */
  %#{$breakpointPrefix}pad {
    padding: #{$defaultOffset}px !important;
  }
  @each $paddingSide in $offsetSides {
    %#{$breakpointPrefix}pad-#{$paddingSide} {
      padding-#{$paddingSide}: #{$defaultOffset}px !important;
    }
  }
  @each $paddingValue in $offsetValues {
    %#{$breakpointPrefix}pad-#{$paddingValue} {
      padding: if($paddingValue == 0, #{$paddingValue}px !important, #{$paddingValue}px !important);
    }
  }
  @each $paddingValue in $offsetValues {
    @each $paddingSide in $offsetSides {
      %#{$breakpointPrefix}pad-#{$paddingSide}-#{$paddingValue} {
        padding-#{$paddingSide}: if(
          $paddingValue == 0,
          #{$paddingValue}px !important,
          #{$paddingValue}px !important
        );
      }
    }
  }
}

@each $breakpointKey, $breakpointWidth in $mainBreakpoints {
  //loop through and add breakpointStyles for each breakpoint
  @if (isFirstInList(map-keys($mainBreakpoints), $breakpointKey)) {
    @include breakpointStyles(null); //don't wrap the first (default) style set in a media query
  } @else {
    @media (min-width: #{$breakpointWidth}px) {
      @include breakpointStyles($breakpointKey + '-');
    }
  }
}

@each $breakpointKey, $breakpointWidth in $mainBreakpoints {
  //loop through and add breakpointStyles for each breakpoint
  @if (isFirstInList(map-keys($mainBreakpoints), $breakpointKey)) {
    @include breakpointPlaceholders(
      null
    ); //don't wrap the first (default) style set in a media query
  } @else {
    @media (min-width: #{$breakpointWidth}px) {
      @include breakpointPlaceholders($breakpointKey + '-');
    }
  }
}

@mixin fillWidthClasses($breakpointPrefix) {
  .#{$breakpointPrefix}fill {
    width: 100% !important;
  }
  .#{$breakpointPrefix}fill-auto {
    width: auto !important;
  }
}

@each $breakpointKey, $breakpointWidth in $mainBreakpoints {
  @if (isFirstInList(map-keys($mainBreakpoints), $breakpointKey)) {
    @include fillWidthClasses(null); //don't wrap the first (default) style set in a media query
  } @else {
    @media (min-width: #{$breakpointWidth}px) {
      @include fillWidthClasses($breakpointKey + '-');
    }
  }
}

%link-white {
  color: getNewTokenColor('neutral', '100');
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: getNewTokenColor('common', 'white');
  }
}

%link-gray {
  color: getNewTokenColor('neutral', '100');
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: getNewTokenColor('neutral', '200');
  }
}
