@import '@amfament/ds-tokens-amfam-web-scss/token-color-map-deep.scss'; // $token-color-map
@import 'token-colors';

%thick {
  &.thick {
    // border** + thick (e.g., border or border-red + thick on the same element)
    border-width: 2px;
  }
}

// primary-600-border
@mixin init-border-colors() {
  @each $mapname, $mapvalue in $token-color-map {
    @each $name, $color in $mapvalue {
      .#{$mapname}-#{$name}-border {
        border: 1px solid getNewTokenColor($mapname, $name);
        @extend %thick;
      }
    }
  }
}
