@import './breakpoints';
@import 'elements/tables'; // TODO - Remove dependency on DSG
@import 'style/stripes'; // TODO - Remove dependency on DSG
@import 'token-colors';
@import 'icons';

$tableRow: getNewTokenColor('common', 'white');

.table {
  &-hover {
    tbody > tr {
      &:hover {
        background-color: darken($tableRow, 2%);
      }
    }
  }

  &-stripe {
    tbody > tr {
      &:nth-of-type(odd) {
        $tableRow: getNewTokenColor('neutral', '100');
        background-color: $tableRow;
      }
    }
  }
}

%tableColHeader {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  a.cell-title {
    font-size: 1.5em;
    font-weight: 400;
    text-decoration: none;
  }
}

.bill-acct-management-datatable {
  .ngx-datatable.material .datatable-header {
    .datatable-header-cell[title=' Accounts'] .datatable-header-cell-label {
      @extend %tableColHeader;
    }
  }
}

.cell-secondary {
  line-height: 1.2em;
  margin: 0.2em 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
  color: getNewTokenColor('neutral', '600');
}

.ngx-datatable,
ds-table {
  span.cell {
    @extend %tableColHeader;
  }

  i {
    &.statement,
    &.method {
      color: getNewTokenColor('neutral', '600');
      &:hover {
        color: getNewTokenColor('secondary', '600');
      }
      &:before {
        text-decoration: none;
        font-size: 2em;
      }
    }
  }

  // the horizontal scroll property [scrollH] changes how the datatable library calculates row widths
  // we need it present so that the widths are calulated correctly. however, we don't want the scroll bar
  // if we need the horizontal scroll, we'll need a new class
  &.scroll-horz .datatable-body {
    overflow-x: hidden !important;
    padding-bottom: 0px !important;
  }

  ds-table-body-row {
    display: block;
    border-bottom: 1px solid getNewTokenColor('neutral', '600');
    margin-bottom: 15px;
    &:last-of-type {
      border-bottom: 1px solid getNewTokenColor('neutral', '600');
    }
    ds-table-body-cell.cell-header:first-of-type + ds-table-body-cell {
      border-top: 0;
      .ds-table-body-cell-label[data-header]:not([data-header=' ']) {
        padding: 10px;
        display: flex !important;
        &:before {
          align-self: center;
          content: attr(data-header);
          width: 50%;
          font-weight: 700;
        }
      }
      .ds-table-body-cell-header-wrapper {
        .ds-table-body-cell-data {
          display: flex !important;
          align-content: center;
        }
        .ds-table-body-cell-header {
          display: none !important;
        }
      }

      @media (max-width: #{map-get($mainBreakpoints,sm)- 1}px) {
        .ds-table-body-cell-label[data-header]:not([data-header=' ']) {
          flex-direction: column !important; // flow the cells into columns under the sm breakpoint
          &:before {
            display: block !important;
            font-size: 1.1em !important; // Enlarge the font a little to make it stand out against the content below it
            padding-bottom: 0.4em !important; // Add a little space below the heder title
            align-self: flex-start !important; // Remove the center alignment
          }
        }
      }
    }

    ds-table-body-cell {
      &.hide-on-mobile {
        border-top: 0;
        .ds-table-body-cell-label {
          @extend .none;
          @extend .md-flex;
        }
        &:last-of-type {
          padding-top: 8px;
        }
      }
    }

    ds-table-body-cell.cell-header {
      border-top: 1px solid getNewTokenColor('neutral', '600');
      & + ds-table-body-cell {
        border-top: 0;
      }
    }
  }

  .ds-table-body-cell-label {
    width: 100%;
    @media (min-width: #{map-get($mainBreakpoints,md)}px) {
      display: flex;
      align-items: center;
    }
  }

  .ds-table-body-cell-header-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    .table-body-cell-label-content {
      width: 100%;
      @media (max-width: #{map-get($mainBreakpoints,md)- 1}px) {
        display: none;
      }
    }
    .ds-table-body-cell-header {
      display: flex !important;
      align-items: center;
      background-color: getNewTokenColor('neutral', '100');
      padding: 12px;
      width: 100%;

      &.expired-expiring {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      @media (min-width: #{map-get($mainBreakpoints,md)}px) {
        display: none !important;
      }
    }
  }

  ds-table-body-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    display: flex;
    align-items: center;

    .ds-table-body-cell-label {
      padding: 0.5rem 0.75rem;
    }

    &:first-of-type {
      .ds-table-body-cell-label {
        padding-left: 0;
      }
    }
    &:last-of-type {
      .ds-table-body-cell-label {
        padding-right: 0;
      }
    }

    @media (max-width: #{map-get($mainBreakpoints,md)- 1}px) {
      width: 100% !important;
      border-left: 1px solid getNewTokenColor('neutral', '600');
      border-right: 1px solid getNewTokenColor('neutral', '600');
      border-top: 1px dashed getNewTokenColor('neutral', '600');

      &:first-of-type {
        .cell-header {
          border-top: 1px solid getNewTokenColor('neutral', '600');
        }
      }

      &.cell-header:first-of-type {
        border-top: 1px solid getNewTokenColor('neutral', '600');
      }

      .ds-table-body-cell-label {
        padding: 0;

        h2 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:not(.no-label) {
          &[data-header]:not([data-header=' ']) {
            padding: 10px;
            display: flex;
            align-content: center;

            &:before {
              display: block !important;
              align-self: center;
              content: attr(data-header);
              width: 50%;
              font-weight: 700;
            }
          }
        }
      }
    }

    @media (min-width: #{map-get($mainBreakpoints,md)}px) {
      .table-body-cell {
        &:before {
          content: none;
        }
      }
    }
  }

  .table-header-cell-wrapper {
    overflow: hidden;
    font-weight: 700;
    font-size: 1.25em;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .table-body-cell-label-content {
    text-align: left;
    vertical-align: top;
    border-top: 0;
    transition: width 0.3s ease;
    overflow: normal;
    display: block;
    .datatable-body-cell-label-actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 0 2px; // gives the button breathing room so it is not effected by the overflow: hidden

      a {
        margin-right: 16px;
      }
    }

    @media (min-width: #{map-get($mainBreakpoints,md)}px) {
      display: flex;
      overflow: hidden;
      align-items: center;
      text-overflow: ellipsis;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

/* I don't think this is ever used.
  We hide the ds-table at all the breakpoints where the sidebar is present and sidebarPresent
  is the thing use to determine whether to put the mobile-only class on the ds-table
*/
ds-table.mobile-only {
  ds-table-body-cell {
    width: 100% !important;
    border-left: 1px solid getNewTokenColor('neutral', '600') !important;
    border-right: 1px solid getNewTokenColor('neutral', '600') !important;
    border-top: 1px dashed getNewTokenColor('neutral', '600') !important;
    &.cell-header:first-of-type {
      border-top: 1px solid getNewTokenColor('neutral', '600') !important;
    }
  }

  ds-table-body-row {
    display: block !important;
  }

  ds-table-header {
    display: none !important;
  }
  .ds-table-body-cell-header-wrapper {
    display: block !important;
    width: 100% !important;
    .ds-table-body-cell-header {
      display: block !important;
    }
    .table-body-cell-label-data {
      display: none !important;
    }
    .table-body-cell-label-content {
      display: none !important;
    }
  }

  ds-table-body-cell {
    &.cell-header:first-of-type + ds-table-body-cell {
      border-top: 0 !important;
    }

    .ds-table-body-cell-label {
      padding: 0px !important;
    }
    .ds-table-body-cell-label:not(.no-label)[data-header]:not([data-header=' ']) {
      padding: 10px !important;
      &:before {
        align-self: center;
        content: attr(data-header);
        width: 50%;
        font-weight: 700;
      }
    }

    &.action-row {
      .datatable-body-cell-label-actions {
        justify-content: flex-start;
        padding: 8px 8px 16px 8px;

        &.mobile-actions {
          display: flex !important;
        }

        &.desktop-actions {
          display: none !important;
        }
      }
    }

    .ds-table-body-cell-data {
      display: none !important;
    }
  }
}

ds-table {
  ds-table-body-cell {
    @media (max-width: #{map-get($mainBreakpoints,sm)- 1}px) {
      .ds-table-body-cell-label:not(.no-label)[data-header]:not([data-header=' ']) {
        flex-direction: column !important; // flow the cells into columns under the sm breakpoint
        &:before {
          display: block !important;
          font-size: 1.1em !important; // Enlarge the font a little to make it stand out against the content below it
          padding-bottom: 0.4em !important; // Add a little space below the heder title
          align-self: flex-start !important; // Remove the center alignment
        }
      }
    }

    @media (max-width: #{map-get($mainBreakpoints,md)- 1}px) {
      &.action-row {
        .datatable-body-cell-label-actions {
          justify-content: flex-start;
          padding: 8px 8px 16px 8px;
        }
      }
    }
  }
}

.ngx-datatable {
  &.material {
    &.noshadow {
      box-shadow: 0 0 0 0;
    }
    .datatable-header,
    .datatable-body {
      .datatable-header-cell {
        padding: 0.5rem 0.75rem !important;

        &:first-of-type {
          padding-left: 0 !important;
        }
        &:last-of-type {
          padding-right: 0 !important;
        }

        .datatable-header-cell-wrapper {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .datatable-header-cell-label {
          font-weight: 700;
        }
        height: 60px;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      .datatable-row-wrapper {
        &:not(:first-of-type) {
          border-top: 1px dashed getNewTokenColor('neutral', '600');
        }
        .datatable-body-row {
          &:hover {
            background-color: getNewTokenColor('common', 'white');
            .datatable-row-group {
              background-color: getNewTokenColor('common', 'white');
            }
          }
        }
      }
      .datatable-body-row {
        .datatable-body-cell {
          display: flex;
          align-items: center;
          padding: 0 !important;
          -webkit-transition: none !important;
          transition: none !important;

          .datatable-body-cell-label {
            overflow: hidden;
            width: 100%;
            padding: 0.5rem 0.75rem !important;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }

          &:first-of-type {
            .datatable-body-cell-label {
              padding-left: 0 !important;
            }
          }
          &:last-of-type {
            .datatable-body-cell-label {
              padding-right: 0 !important;
            }
          }
        }
      }
    }

    .datatable-header .datatable-header-cell {
      .sort-,
      .sortable {
        padding-left: 4px;
        color: getNewTokenColor('neutral', '600');
        &asc {
          @extend .icon-sort-asc-lg;
        }
        &desc {
          @extend .icon-sort-desc-lg;
        }
      }
    }

    .datatable-header {
      border-bottom-color: getNewTokenColor('neutral', '600') !important;
    }

    /**
    * Body Styles
    */

    &.scroll-horz {
      .datatable-body {
        padding-bottom: 20px;
      }
    }
    .datatable-body {
      .datatable-row-detail {
        padding: 0 !important;
        background: getNewTokenColor('common', 'white') !important;
        margin: -1px 0 10px 0 !important;
      }

      .datatable-body-row > div {
        &a {
          color: getNewTokenColor('primary', '800');
        }
        &.active,
        &.active .datatable-row-group {
          color: getNewTokenColor('primary', '200');
        }
        &:hover,
        &:hover .datatable-row-group {
          background-color: transparent !important;
        }
      }
      .datatable-scroll {
        display: block !important;
        width: 100% !important;
      }
    }

    /**
    * Pager Styles
    */
    .datatable-footer {
      .page-count {
        padding: 0 !important;
      }
      .datatable-pager {
        display: block;
        margin: 0 !important;
        .pager {
          li {
            &.disabled {
              visibility: hidden;
            }
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              i {
                font-size: 0.75em;
              }
              &:focus {
                // Enable browser default focus for a11y
                outline: auto 5px Highlight;
                outline: 5px auto -webkit-focus-ring-color;
              }
            }
          }
        }
      }
    }
  }

  &.material.expandable .datatable-body .datatable-body-row {
    .datatable-body-cell {
      &:first-of-type,
      &:last-of-type {
        .datatable-body-cell-label {
          padding-left: 0.75rem !important;
        }
      }
    }
  }

  &.payment-automatic,
  &.payment-scheduled,
  &.payment-history,
  &.payment-method {
    .datatable-body {
      .datatable-row-wrapper {
        border: 2px solid transparent; // Transparent border to keep cell content from shifting when expanded
        &.expanded {
          border: 2px solid getNewTokenColor('error', '600');
          + .datatable-row-wrapper {
            border-top: 0;
          }
          .datatable-body-row {
            &:hover {
              background-color: transparent !important;
              .datatable-row-group {
                background-color: transparent !important;
              }
            }
          }
        }
      }
      .datatable-row-detail {
        white-space: normal;
      }
      .datatable-body-row {
        .datatable-row-group {
          -webkit-transition: none;
          transition: none;
        }
        &:hover {
          background-color: transparent;
          .datatable-row-group {
            background-color: transparent;
          }
        }
        .datatable-body-cell {
          // Remove the left to right cell enter animations
          -webkit-transition: none !important;
          transition: none !important;
          border-bottom: none;

          display: inherit;
          align-items: initial;
          .datatable-body-cell-label {
            // pad left and right on first and last children as well
            padding: 0.5rem 0.75rem !important;
          }
          .datatable-body-cell-label > div,
          .datatable-body-cell-label > .cell-component > div {
            &.table-body-cell-label-content {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.responsive {
    .datatable-body .datatable-body-row .datatable-body-cell {
      overflow: normal !important;
      white-space: normal !important;
      word-break: break-word !important;
    }
  }
}

#billing-detail {
  #payment-history-component,
  #billing-statement-history-component {
    ds-table {
      ds-table-body-cell {
        @media (min-width: #{map-get($mainBreakpoints,md)}px) {
          .ds-table-body-cell-data {
            display: none !important;
          }
        }
        .ds-table-body-cell-label:not(.no-label)[data-header='Date'] {
          padding: 0 !important;
          &:before {
            display: none !important;
          }
          @media (max-width: #{map-get($mainBreakpoints,sm)- 1}px) {
            .ds-table-body-cell-header-wrapper {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#payment-history-component,
#billing-statement-history-component {
  ds-table ds-table-body-cell {
    &.cell-header {
      border-top: 1px solid getNewTokenColor('neutral', '600');
      & + ds-table-body-cell {
        border-top: 1px dashed getNewTokenColor('neutral', '600');
      }
      &:first-of-type {
        & + ds-table-body-cell {
          border-top: 0;
        }
      }
    }
  }
}

// Fixes a bug in IE8+, which does not inherit text-align from the <th> parent
table thead th {
  text-align: left;
}
