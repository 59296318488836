@import '../token-colors';

.loading-container {
  position: fixed;
  top: 20%;
  width: 100%;
  text-align: center;
  z-index: 7010; //$modalZIndex - 10
  animation: loading-open 0.2s ease-in-out;
  pointer-events: none;

  @keyframes loading-open {
    from {
      opacity: 0;
      transform: scale(0.25);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.closing {
    opacity: 0;
    transform: scale(0.25);
    transition:
      opacity 0.2s ease-in-out,
      transform 0.2s ease-in-out;
  }

  .box {
    display: inline-block;
    background-color: getNewTokenColor('common', 'white');
    padding: 40px 80px;
    border-radius: 3px;
    border: 1px solid getNewTokenColor('neutral', '600');
    pointer-events: all;

    .spinner {
      display: inline-block;
      height: 80px;
      width: 80px;
      background-color: getNewTokenColor('common', 'white');
      border-radius: 50%;
      padding: 8px;
      overflow: hidden;
      -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); //fix overflow hidden in webkit
      position: relative;

      &:before {
        content: '';
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;
        border-left: 40px solid getNewTokenColor('primary', '800');
        top: 0;
        left: 0;
        position: absolute;
        transform-origin: right center;
        animation: loading-spinner-spin 0.75s infinite linear;
      }

      @keyframes loading-spinner-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      &:after {
        content: '';
        display: block;
        position: relative;
        height: 100%;
        background-color: getNewTokenColor('common', 'white');
        border-radius: inherit;
      }
    }

    .heading-main {
      margin: 10px 0 0;
    }
  }
}
