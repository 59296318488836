@import './colors';

.stripe {
  @at-root {
    ul#{&},
    ol#{&} {
      > li:nth-child(odd) {
        background-color: getColor('gray-lt');
      }
    }

    table#{&} tbody > tr:nth-of-type(odd) {
      background-color: getColor('gray-lt');
    }
  }
}
