@import '../style/colors';

@mixin tableHeadings($headings...) {
  $headingsList: $headings;

  @each $heading in $headingsList {
    tbody > tr > td:nth-of-type(#{index($headingsList, $heading)}):before {
      content: $heading;
    }
  }
}

@mixin tableStyles($breakpointPrefix, $breakpointWidth) {
  &.#{$breakpointPrefix}small {
    thead {
      display: none;
    }

    tbody > tr > td {
      display: block;

      &:before {
        display: block;
      }

      &[data-label]:before {
        content: attr(data-label);
      }
    }

    &.chart {
      tbody > tr > td {
        &:first-child {
          font-weight: bold;
          color: getColor('white');
          background-color: getColor('heading-gray');
        }
      }

      &.stripe tbody > tr > td {
        &:not(:first-child) {
          background-color: getColor('gray-lt');
          &:nth-of-type(odd) {
            background-color: getColor('white');
          }
        }
      }
    }
  }

  &.#{$breakpointPrefix}medium {
    thead {
      display: none;
    }

    tbody > tr > td {
      display: flex;
      overflow: hidden;

      &:before {
        display: inline-block;
        width: 50%;
        flex-shrink: 0;
      }

      &[data-label]:before {
        content: attr(data-label);
      }
    }

    &.chart {
      tbody > tr > td {
        &:first-child {
          font-weight: bold;
          color: getColor('white');
          background-color: getColor('heading-gray');
        }
      }

      &.stripe tbody > tr > td {
        &:not(:first-child) {
          background-color: getColor('gray-lt');
          &:nth-of-type(odd) {
            background-color: getColor('white');
          }
        }
      }
    }
  }

  &.#{$breakpointPrefix}large {
    thead {
      display: table-header-group;
    }

    tbody > tr > td {
      display: table-cell;

      &:before {
        display: none;
      }
    }

    &.chart {
      tbody > tr > td {
        padding: 15px;

        &:first-child {
          font-weight: normal;
          color: inherit;
          background-color: inherit;
        }
      }

      &.stripe tbody > tr > td {
        &:not(:first-child) {
          background-color: inherit;
          &:nth-of-type(odd) {
            background-color: inherit;
          }
        }
      }
    }
  }
}

table {
  position: relative;
  margin-bottom: 1em;

  thead {
    color: getColor('heading-gray');
  }

  th,
  td {
    text-align: left;
    padding: 0.5em 15px;
  }

  td:before {
    display: none;
    font-weight: bold;
    padding-right: 0.5em;
  }

  &.chart {
    th,
    td {
      justify-content: space-between;

      &:first-child {
        white-space: nowrap;
      }
    }

    thead {
      color: getColor('white');
      background-color: getColor('heading-gray');
    }

    tbody > tr > td:first-child {
      &[data-label]:before {
        display: none;
      }
    }
  }

  @each $breakpointKey, $breakpointWidth in $mainBreakpoints {
    // loop through and add tableStyles for each breakpoint
    @if (isFirstInMap(map-keys($mainBreakpoints), $breakpointKey)) {
      @include tableStyles(
        null,
        $breakpointWidth
      ); // don't wrap the first (default) style set in a media query
    } @else {
      @media (min-width: #{$breakpointWidth}px) {
        @include tableStyles($breakpointKey + '-', $breakpointWidth);
      }
    }
  }
}
