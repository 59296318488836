@import '../style/colors';

.banner {
  position: relative;
  background-color: getColor('gray-lt');
  padding: 15px;
  display: flex;
  align-content: center;

  &.warning {
    background-color: getColor('yellow');
  }
  &.notification {
    background-color: getColor('sky-blue');
  }

  > .message {
    flex-grow: 1;
    text-align: center;
  }

  > .icon-exit {
    background-color: transparent;
    color: inherit;
    padding: 5px;
    margin-left: 15px;
  }

  @media (min-width: #{map-get($mainBreakpoints,md)}px) {
    padding: 15px 30px;

    @at-root header + & {
      margin-top: 70px;
      padding-top: 25px;
    }
  }
}
