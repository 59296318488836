@import 'token-colors';
@import 'elements/banners';

.banner {
  justify-content: center;
  .close {
    align-self: center;
    color: inherit;
  }
  &.info {
    background-color: getNewTokenColor('info', '100');
  }
  & .message {
    padding: 0 20px;
  }
}
