.shrink {
  flex-shrink: 1 !important;
}

.noshrink {
  flex-shrink: 0 !important;
}

.grow {
  flex-grow: 1 !important;
}

.nogrow {
  flex-grow: 0 !important;
}

@each $wrapValue in wrap, nowrap {
  .#{$wrapValue} {
    flex-wrap: $wrapValue;
  }
}

.grow-wrap {
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
  }
}

.spaced-grow-wrap {
  @extend .grow-wrap;
  margin: -8px;

  & > * {
    margin: 8px;
  }

  & > .spaced-grow-wrap {
    margin: 0;
  }
}

.spaced-form-grow-wrap {
  @extend .grow-wrap;
  margin: -4px -8px;

  & > * {
    margin: 4px 8px;
  }

  & > .spaced-form-grow-wrap {
    margin: 0;
  }
}

@mixin breakpointFlexStyles($breakpointPrefix) {
  @each $justifyContentValue in flex-start, flex-end, center, space-between, space-around {
    .#{$breakpointPrefix}justify-#{$justifyContentValue} {
      justify-content: $justifyContentValue;
    }
  }

  @each $alignContentValue in flex-start, flex-end, center, space-between, space-around, stretch {
    .#{$breakpointPrefix}content-#{$alignContentValue} {
      align-content: $alignContentValue;
    }
  }

  @each $alignItemsValue in flex-start, flex-end, center, baseline, stretch {
    .#{$breakpointPrefix}items-#{$alignItemsValue} {
      align-items: $alignItemsValue;
    }
  }

  @each $alignSelfValue in auto, flex-start, flex-end, center, baseline, stretch {
    .#{$breakpointPrefix}self-#{$alignSelfValue} {
      align-self: $alignSelfValue;
    }
  }
}

@each $breakpointKey, $breakpointValue in $mainBreakpoints {
  @if (isFirstInMap(map-keys($mainBreakpoints), $breakpointKey)) {
    @include breakpointFlexStyles(null);
  } @else {
    @media (min-width: #{$breakpointValue}px) {
      @include breakpointFlexStyles($breakpointKey + '-');
    }
  }
}
