@import 'token-colors';

/*
  If .swiper-contain has a -pagination modifier class,
  push the pagination below the slides
*/
.swiper-container {
  &.-pagination {
    .swiper-slide {
      margin-bottom: 50px;
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }
  &.-navigation {
    .swiper-button-prev,
    .swiper-button-next {
      top: 22px;
      height: calc(100% - 50px);
      width: 50px;
      background-color: getNewTokenColor('neutral', '100');
      background-image: none;
      font-family: $fontIcons;
      color: getNewTokenColor('primary', '800');
      &:before {
        position: relative;
        top: calc(50% - 18px);
        left: 7px;
        font-size: 36px;
      }
    }
    &.-mobile .swiper-button-prev,
    &.-mobile .swiper-button-next {
      background-color: getNewTokenColor('common', 'white');
    }
    .swiper-slide .tile {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .swiper-button-next {
      background-image: none;
      &:before {
        content: '\edb9';
      }
    }
    .swiper-button-prev {
      background-image: none;
      &:before {
        content: '\edc2';
      }
    }
  }
  &.-border {
    .swiper-slide {
      border: 1px solid getNewTokenColor('neutral', '200');
      border-radius: 4px;
      background: getNewTokenColor('common', 'white');
      height: auto !important; // Has to be here to override the third-party swiper css
    }
    &.-mobile .swiper-slide {
      border: none;
      .swiper-slide-content {
        padding: 0 50px;
      }
    }
  }
}
