.button-group {
  overflow: auto;
  > button {
    white-space: nowrap;
    float: left;
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      border-left: none;
    }
    &:first-child:not(:last-child) {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child:not(:first-child) {
      border-radius: 0;
      border-left: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  // Vertical button groups
  // ----------------------

  &-vert {
    > button {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;

      &:not(:first-child):not(:last-child) {
        border-radius: 0;
      }
      &:first-child:not(:last-child) {
        border-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child:not(:first-child) {
        border-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    > button + button,
    > button + .button-group,
    > .button-group + button,
    > .button-group + .button-group {
      margin-top: -1px;
      margin-left: 0;
    }
  }
}
