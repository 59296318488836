@import './typography-core';
@import 'token-colors';
@import './breakpoints';

/* include mixin in heading element in which you want heading lines added*/
/* make sure to pass width and top property to mixin for it to work*/

@mixin heading-lines($topAlignment, $width) {
  margin-bottom: 1em;
  @media (min-width: #{map-get($mainBreakpoints,sm)}px) and (max-width: #{map-get($mainBreakpoints,lg)-1}px) {
    text-align: center;
    position: relative;
    margin-top: 1em;

    &:after,
    &:before {
      content: '';
      height: 1px;
      background-color: getNewTokenColor('neutral', '200');
      position: absolute;
      top: $topAlignment;
      width: $width;
    }

    &:after {
      left: 0;
    }

    &:before {
      right: 0;
    }
  }
}

/// Font Heights
/// @group Fonts
/// @group Maps
/// @type Map
/// @prop {String} 4em
/// @prop {String} 3em
/// @prop {String} 2em
/// @prop {String} 1.75em
/// @prop {String} 1.5em
/// @prop {String} 1.25em
/// @prop {String} 1em
/// @prop {String} .85em
/// @prop {String} .75em
/// @prop {String} .5em
$fontSizeValues: (
  '4': 4,
  '3': 3,
  '2': 2,
  '1-75': 1.75,
  '1-50': 1.5,
  '1-25': 1.25,
  '1': 1,
  '0-85': 0.85,
  '0-75': 0.75,
  '0-5': 0.5
);

/// Font Weights
/// @group Fonts
/// @group Maps
/// @type Map
/// @prop {String} normal:      400
/// @prop {String} semibold:    600
/// @prop {String} bold:        700
$fontWeightValues: (
  'normal': 400,
  'semibold': 600,
  'bold': 700
);

/*  ------------------------
Typography Placeholders Mixins
------------------------- */
@mixin fontSizePlaceholders($breakpointPrefix) {
  @each $key, $size in $fontSizeValues {
    %#{$breakpointPrefix}font-size-#{$key} {
      font-size: #{$size}em !important;
    }
  }
}

@mixin fontWeightPlaceholders() {
  @each $key, $height in $fontWeightValues {
    %font-weight-#{$key} {
      font-weight: #{$height} !important;
    }
  }
}

/*  ------------------------
Loop over breakpoints map to create placeholders
------------------------- */
@each $breakpointKey, $breakpointWidth in $mainBreakpoints {
  @if (isFirstInList(map-keys($mainBreakpoints), $breakpointKey)) {
    @include fontSizePlaceholders(null);
    @include fontWeightPlaceholders();
  } @else {
    @media (min-width: #{$breakpointWidth}px) {
      @include fontSizePlaceholders($breakpointKey + '-');
    }
  }
}

/*  ------------------------
Typography Utility Placeholders
------------------------- */

%caption {
  @extend %font-size-0-85;
}

/*  ------------------------
Typography Class Mixins
------------------------- */
@mixin fontWeightClasses() {
  @each $key, $weight in $fontWeightValues {
    .font-weight-#{$key} {
      font-weight: #{$weight} !important;
    }
  }
}

@mixin fontSizeClasses($breakpointPrefix) {
  @each $key, $size in $fontSizeValues {
    .#{$breakpointPrefix}font-size-#{$key} {
      font-size: #{$size}em !important;
    }
  }
}

@mixin miscClasses() {
  .whitespace-nowrap {
    white-space: nowrap !important;
  }
  .whitespace-normal {
    white-space: normal !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-scroll {
    overflow: scroll !important;
  }
  .overflow-visible {
    overflow: visible !important;
  }
  .overflow-ellipsis {
    text-overflow: ellipsis !important;
  }

  .no-decoration {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
    &:before:hover {
      text-decoration: none !important;
    }
  }
  .caption {
    @extend %caption;
  }
  .truncate {
    max-width: 95%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  blockquote {
    &.border {
      padding-left: 1.4em;
      border-left-width: 4px;
      border-left-style: solid;
      margin: 20px 10px;
      max-width: 100%;
      border-color: getNewTokenColor('neutral', '100');
      &.-primary-800 {
        border-color: getNewTokenColor('primary', '800');
      }
      &.-error-600 {
        border-color: getNewTokenColor('error', '600');
      }
      &.-success-600 {
        border-color: getNewTokenColor('success', '600');
      }
    }
  }
}

/*  ------------------------
Typography Class Init Mixin
 - Used in style.scss
------------------------- */
@mixin init-typography-classes() {
  .b,
  b {
    @extend %font-weight-bold;
  }

  @each $breakpointKey, $breakpointWidth in $mainBreakpoints {
    @if (isFirstInList(map-keys($mainBreakpoints), $breakpointKey)) {
      @include fontSizeClasses(null);
      @include fontWeightClasses();
      @include miscClasses();
    } @else {
      @media (min-width: #{$breakpointWidth}px) {
        @include fontSizeClasses($breakpointKey + '-');
      }
    }
  }
}

p.quote,
span.quote {
  font-family: $fontGeorgia;
}
