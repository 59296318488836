@import 'core/core';

.touched nav#top-navigation .top > li {
  &:hover,
  &:active {
    .sub {
      display: inherit;
      visibility: hidden;
      opacity: 0;
    }
  }
}
