@import './grid';
@import './style/colors'; // Needed for elements/forms
@import './breakpoints';
@import './typography'; // Something else is bringing in $fontGeorgia, let's get it from 'almost' the source instead
@import './icons'; // Something else is bringing in $fontIcons, let's get it from the source instead
@import 'token-colors';

$fontFallback: sans-serif;

$fontOpenSans: 'Open Sans', $fontFallback;
$fontOswald: 'Oswald', $fontFallback;
$fontGeorgia: 'Georgia', $fontFallback;
$fontIcons: 'icomoon';

@mixin formElementFocusBorder {
  border-color: getNewTokenColor('primary', '800');
  outline: 0;
  box-shadow: 0 0 2px getNewTokenColor('primary', '800');
}

@mixin formElementBorder {
  border: 1px solid getNewTokenColor('neutral', '600');
  transition: border-color 0.15s linear;
}

@mixin init-form-classes() {
  button:focus {
    @include formElementFocusBorder();
  }
  input[type='text'],
  input[type='password'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .checkbox {
    opacity: 0;
    position: absolute;
    cursor: none;
  }
  .checkbox-label {
    outline: none;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    > p {
      margin: 0;
      padding-top: 6px;
    }
  }
  .checkbox + .checkbox-label {
    &:before {
      content: '';
      background: getNewTokenColor('common', 'white');
      border: 1px solid getNewTokenColor('neutral', '600');
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin: 2px 10px 2px 2px;
      text-align: center;
    }
    p {
      padding-top: 0;
      font-family: $fontGeorgia;
      font-style: italic;
    }
  }
  .checkbox:checked + .checkbox-label:before {
    font-family: $fontIcons;
    content: '\ed6e';
    color: getNewTokenColor('primary', '800');
    font-size: 1.4em;
    line-height: 0.6em;
  }
  .checkbox:checked:disabled + .checkbox-label:before {
    font-family: $fontIcons;
    content: '\ed6e';
    color: getNewTokenColor('neutral', '200');
    background-color: getNewTokenColor('neutral', '100');
    font-size: 1.4em;
    line-height: 0.4em;
  }
  // Enable browser default focus for a11y
  .checkbox:focus + .checkbox-label:before {
    outline: auto 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color;
    border-color: getNewTokenColor('primary', '800');
    box-shadow: 0 0 2px getNewTokenColor('primary', '800');
  }
  .checkbox:disabled + .checkbox-label:before {
    background-color: getNewTokenColor('neutral', '100');
  }

  .radio-toggle-collection {
    &:not(:first-of-type) {
      margin-top: 20px;
      border-top: 1px dashed getNewTokenColor('neutral', '600');
    }
  }

  // Paragraphs and spans inside of labels shouldn't be italic or Georgia
  label p {
    font-style: normal;
    font-family: $fontOpenSans;
  }
}

@mixin fromPlaceholderTextContent($placeholderColor) {
  color: #{$placeholderColor};

  font: {
    family: $fontGeorgia;
    size: 0.9em;
    style: italic;
    weight: 400;
  }
}

@mixin formPlaceholderText($placeholderColor) {
  & {
    font-family: $fontOpenSans;
  }

  &::-webkit-input-placeholder {
    @include fromPlaceholderTextContent($placeholderColor);
    line-height: 2em;
  }

  &:-moz-placeholder {
    @include fromPlaceholderTextContent($placeholderColor);
    line-height: 1.7em;
  }

  &::-moz-placeholder {
    @include fromPlaceholderTextContent($placeholderColor);
    line-height: 1.7em;
  }

  &:-ms-input-placeholder {
    @include fromPlaceholderTextContent($placeholderColor);
    font-size: 1em; //IE's placeholder font-size changes input height when empty, so fix
    line-height: 1.1em;
  }
}

@mixin inputStyle {
  padding: 0.45em 0.7em;
  height: 2.5em;
  border-radius: 0.125em;
  color: getNewTokenColor('neutral', '800');
  background: getNewTokenColor('common', 'white');

  @include formPlaceholderText(getNewTokenColor('neutral', '600'));
  @include formElementBorder();

  @at-root .invalid & {
    border-color: getNewTokenColor('error', '600');
  }

  &:focus {
    @include formElementFocusBorder();
  }

  &.icon {
    max-width: 100%;
    padding-right: 2.5em;
    position: relative;

    & ~ span[class*='icon-'] {
      pointer-events: none;
      position: absolute;
      font-size: 1.3em;
      margin: 1em 0 0 -1.6em;
      color: getNewTokenColor('neutral', '600');
      &[onclick] {
        pointer-events: all;
        cursor: pointer;

        &:hover {
          color: getNewTokenColor('secondary', '600');
        }
      }
    }
  }

  &:disabled {
    -webkit-opacity: 1;
    -webkit-text-fill-color: getNewTokenColor('neutral', '800');
  }

  @for $size from 1 to 50 {
    &[size='#{$size}'] {
      min-width: calc(2.5em + #{$size}em);
    }
  }
}

label,
legend {
  position: relative;
  cursor: pointer;
  padding: 0 0.35em 0 0.15em;
  color: getNewTokenColor('neutral', '600');
  font-family: $fontGeorgia;
  font-style: italic;
  font-weight: 400;
  a {
    font-weight: 400;
  }
}

// grey-out the element's background and add mouse indicator if it is disabled
input:disabled,
select:disabled {
  background: getNewTokenColor('neutral', '100');
  cursor: not-allowed;
}

.input-group-addon,
.input-pad {
  margin-bottom: 0.3em;
}

.ccInput {
  display: flex;
  align-items: center;

  ds-credit-card-icon {
    margin-top: 0.6em;
    padding-left: 0.5em;
  }
}

.column {
  @media (min-width: #{map-get($mainBreakpoints,md)}px) {
    &.md-inline-form-wrapper {
      line-height: 0.8em;
    }
  }
}

/* =============================================
 Global Form Helpers - Moved from styles.scss
============================================== */
.input-inline {
  button {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
  }
}

// Override style guide
legend {
  font-style: normal !important;
  font-family: $fontOpenSans !important;
}

input[type='radio'] {
  float: left;
  clear: both;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  display: block;
  width: 1em;
  height: 1em;
  cursor: pointer;
}

input[type='radio']:before {
  border-radius: 100%;
  content: '';
  width: 16px;
  height: 16px;
  border: 1px solid getNewTokenColor('neutral', '600');
  transition: border-color 0.15s linear;
  position: absolute;
}

input[type='radio']:checked:after {
  content: '';
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: getNewTokenColor('primary', '800');
  position: absolute;
}

/** ie 10+ */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  input[type='radio'] {
    padding: 3px;
  }

  input[type='radio']:before {
    padding: 0.15em;
  }
}

input[type='radio']:checked:before {
  color: inherit;
}

input[type='radio']:focus:before {
  // Enable browser default focus for a11y
  outline: auto 2px Highlight;
  outline: auto 5px -webkit-focus-ring-color;
}

input[type='radio']:disabled,
input[type='radio']:disabled:before {
  cursor: not-allowed;
  background-color: getNewTokenColor('neutral', '100');
}

input[type='radio']:disabled:checked:before {
  background-color: getNewTokenColor('common', 'white');
}

input[type='radio']:disabled:checked:after {
  background-color: getNewTokenColor('neutral', '200');
}

// Enable styling from the ds-form-control-container using the class opposed to the element
// This is because KYD isn't using reactive forms and I need to fix a release bug quickly.
// TODO: Replace forms in KYD with reactive forms.

.form-control-container {
  display: inline-block;
  width: 100%;

  .error {
    font-size: 0.85em;
    color: getNewTokenColor('error', '600');
  }

  label {
    position: relative;
    cursor: pointer;
    padding: 0 0.35em 0 0.15em;
    color: getNewTokenColor('neutral', '800');
  }

  .accent,
  label {
    font-family: 'Georgia', sans-serif;
    font-style: italic;
    font-weight: 400;
  }

  .form-group {
    &.has-error {
      input,
      select {
        border-color: getNewTokenColor('error', '600');
        &:focus {
          box-shadow: 0 0 2px getNewTokenColor('error', '600');
        }
      }
    }
  }

  .input-group {
    position: relative;
    display: inline-block;
    .input {
      position: relative;
      padding-top: 0; // Override 0.6em padding from the DSG
      label {
        position: absolute;
        font-size: 0.9em;
        line-height: 1em;
        left: 0.4em;
        margin-right: 0.4em;
        top: 0%;
        margin-top: 1.5em;
        padding: 0 0.25em;
        color: getNewTokenColor('neutral', '600');
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
      }

      input,
      textarea,
      select {
        margin-top: 0.6em;
        background-color: getNewTokenColor('common', 'white');

        &:focus,
        &:not([value='']) {
          & ~ label {
            background: getNewTokenColor('common', 'white');
          }
        }

        & ~ label {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }

        &:focus {
          // Enable browser default focus for a11y
          outline: auto 2px Highlight;
          outline: auto 5px -webkit-focus-ring-color;
          & ~ label {
            -webkit-transform: translateY(-1.3em) !important;
            transform: translateY(-1.3em) !important;
            background: getNewTokenColor('common', 'white');
            color: getNewTokenColor('neutral', '800');
          }
        }
        &:not([value='']) {
          & ~ label {
            -webkit-transform: translateY(0);
            transform: translateY(0);
          }
        }
      }

      label.floating,
      label.no-float {
        -webkit-transform: translateY(-1.3em) !important;
        transform: translateY(-1.3em) !important;
        background: getNewTokenColor('common', 'white');
        color: getNewTokenColor('neutral', '800');
      }
    }
  }

  // Fix to add browser default focus style
  .input.a11y {
    input,
    textarea,
    select {
      margin: 0.6em -4px 0 2px;
      &.fill {
        width: calc(100% - 4px) !important;
      }
    }
    label {
      left: 0.6em;
    }
  }

  input,
  textarea,
  select {
    @include inputStyle();
    height: 2.5em;
  }

  textarea {
    @include inputStyle();
  }

  textarea {
    resize: vertical;
    height: 5.5em;

    &.icon {
      overflow-y: scroll;

      & ~ span[class*='icon'] {
        margin-left: -43px;
      }
    }
  }

  select.icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
  }

  select.icon::-ms-expand {
    display: none;
  }
}

// Init all the mixins - moved here from styles.scss
@include init-form-classes;

/*# sourceURL=forms.scss */
