@import 'token-colors';
@import 'breakpoints';

.tiles > .column {
  // overriding amfam styleguide
  border-bottom: none;
  padding-top: 0;
}

// Overview tiles

.row.tiles > .column {
  // layout: one card to a row
  @media (max-width: 670px) {
    padding-right: 0;
    padding-left: 0;
  }

  // layout: two cards to a row from 1024-671
  &.--claims,
  &.--billing,
  &.--policy,
  &.--programs {
    @media (min-width: 671px) and (max-width: 1024px) {
      width: 50%;
      &:nth-of-type(2n) {
        padding-right: 0;
      }

      &:nth-of-type(2n + 1) {
        padding-left: 0;
      }
    }
  }

  // layout: three cards to a row
  @media (min-width: 1025px) {
    &:nth-of-type(3n) {
      padding-right: 0;
    }

    &:nth-of-type(3n + 1) {
      padding-left: 0;
    }
  }
}

.tile-heading {
  width: 100%;
}

.info-tile {
  .tile-heading {
    display: flex;
    width: 100%;
    height: 60px;
    color: getNewTokenColor('neutral', '800');
    background-color: getNewTokenColor('neutral', '100');
    align-items: center;
    padding: 8px 16px;
  }
  .tile-content {
    padding: 15px 0;
  }
  .tile-cta {
    width: 100%;
    > button,
    a.button {
      float: right;
    }
  }
}

.alert-tile {
  .tile-heading {
    color: getNewTokenColor('warning', '100');
    display: flex;
    width: 100%;
    height: 60px;
    color: getNewTokenColor('neutral', '800');
    align-items: center;
    padding: 8px 16px;
  }
  .tile-content {
    padding-top: 15px;
  }
}

.truncate-string-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.billing-tile {
  width: 343px !important;
  position: relative;
  .tile {
    border: 1px solid getNewTokenColor('neutral', '200');
  }
  .tile-status-pill {
    padding: 4px 16px;
    border-radius: 15px;
    font-size: 12px;
    line-height: 22px;
    color: white;
    &.error-pill {
      background-color: #d12421;
    }
    &.warning-pill {
      background-color: #1f66e0;
    }
    &.success-pill {
      background-color: #157d1e;
    }
  }
  .tile-title {
    font-size: 22px;
    line-height: 34px;
    font-weight: 600;
    font-family: 'Open Sans';
  }
  .tile-heading {
    width: 100%;
    padding: 24px 24px 10px;
    color: #202630;
    border-radius: 8px 8px 0 0;

    .heading-icon {
      font-size: 48px;
      padding-right: 24px;
    }
    .heading-text {
      overflow: hidden;
      p {
        &:not(:last-of-type) {
          margin-bottom: 0.2em;
        }
      }
      &-subheading {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
      }
      &-risks {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-last-payment {
        font-size: 0.9em;
        margin-top: 7px;
        margin-bottom: 18px;
      }
    }
  }

  .tile-content {
    padding-top: 8px;
    font-weight: 600;
    line-height: 14px;
    width: 100%;
    span[class*='icon-'] {
      padding-right: 8px;
    }
  }
  .tile-cta {
    align-self: flex-end;
    display: flex;
    white-space: nowrap;
    width: 100%;
    button,
    a.button {
      flex: 1 1;
      white-space: normal;
      text-transform: none;
      font-size: 16px;
      font-weight: 600;
      font-family: 'Open Sans';
      @media (max-width: #{map-get($mainBreakpoints,sm)}px) {
        letter-spacing: 0;
      }
    }
  }

  .primaryIconFont {
    color: #384252;
  }
  .icon-info-container {
    color: getNewTokenColor('primary', '800');
  }
  .icon-complete-circle-container {
    color: getNewTokenColor('success', '600');
  }
  .icon-alert-container {
    color: getNewTokenColor('error', '600');
  }
}

.claims-tile {
  .tile-heading {
    height: 132px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: getNewTokenColor('primary', '800');
    color: getNewTokenColor('common', 'white');
    border-radius: 3px 3px 0 0;
    padding-top: 40px;

    .heading-icon {
      font-size: 48px;
      padding-right: 24px;
    }
    .heading-text {
      overflow: hidden;
      p {
        &:not(:last-of-type) {
          margin-bottom: 0.2em;
        }
      }
      &-subheading {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
      }
      &-risks {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-last-payment {
        font-size: 0.9em;
        margin-top: 7px;
        margin-bottom: 18px;
      }
    }
  }

  .tile-content {
    padding: 15px 0;
    line-height: 1.2em;
    width: 100%;
    span[class*='icon-'] {
      padding-right: 8px;
    }
  }
  .tile-cta {
    align-self: flex-end;
    display: flex;
    white-space: nowrap;
    width: 100%;
    button,
    a.button {
      flex: 1 1 auto;
      white-space: normal;
    }
  }

  .icon-info-container {
    color: getNewTokenColor('primary', '800');
  }
  .icon-complete-circle-container {
    color: getNewTokenColor('success', '600');
  }
  .icon-alert-container {
    color: getNewTokenColor('error', '600');
  }
}

.policy-tile,
.program-tile {
  .tile {
    flex-direction: column;
  }

  .tile-heading {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 24px;

    .heading-icon {
      font-size: 48px;
      padding: 0px 12px 4px 4px;
      color: getNewTokenColor('primary', '800');
      &.icon-mail {
        line-height: 0.8em;
      }
    }
    .heading-text {
      flex-wrap: wrap;
      align-self: flex-start;
      overflow: hidden;
      .heading-section {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 600;
      }
    }
  }

  .tile-cta {
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    button,
    a.button {
      flex: 1 1 auto;
      white-space: normal;
    }
  }
}

// Changes needed for the policy tile because it's using spaced-grow-wrap for the button group
// This removes the extra padding that is created.
.policy-tile {
  .tile-heading {
    padding-bottom: 0;
  }
  .tile-content-wrapper {
    padding: 0 8px 8px 8px !important;
  }
  .heading-section {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: getNewTokenColor('neutral', '800');
  }
}

.sticky-container {
  box-shadow: 0px -4px 4px 0px rgba(getNewTokenColor('common', 'black'), 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 16px 16px 16px;
  background-color: getNewTokenColor('primary', '100');
  position: sticky;
  bottom: 0; /* This will stick the div to the bottom of the container */
  button {
    width: 307px;
  }
  @media (max-width: #{map-get($mainBreakpoints,md)}px) {
    padding: 0px 12px 12px 12px;
    // This will create side by side layout of buttons
    call-to-action {
      width: 100%;
      .button-container {
        flex-direction: row;
        padding-top: 12px;
        .primary-button {
          width: 71% !important;
          margin: 0 !important;
        }
        .secondary-button {
          width: 25% !important;
          margin: 0px 12px 0px 0px !important;
        }
      }
    }
  }
}
