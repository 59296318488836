@import './policy.scss';
@import 'token-colors';

// 01 - Mobile first - Also default for other screens

section {
  // Pad all paragraphs that are the first in their container
  // Container must be nested inside .column
  // <row tiles>
  //   <column>
  //     <p> // not me
  //     <container>
  //       <p> // me
  .row.tiles > .column > * > p:nth-of-type(1) {
    margin-top: 18px;
  }

  &#billing {
    table {
      tbody > tr > td {
        display: table-cell;
      }
    }
    .next-bill {
      margin-top: 14px;
      padding: 10px;
      background-color: getNewTokenColor('neutral', '200');
      flex-flow: row wrap;

      .left {
        min-width: 100%;
      }
      .right {
        text-align: right;
        flex: 1;
      }

      .actions {
        align-items: flex-end;
        justify-content: space-between;
        flex-flow: row;
        flex: 1 100%;

        button {
          margin-top: 20px;
          width: 48%;
        }
      }
    }
  }

  &#claims {
    table {
      tbody > tr > td {
        display: table-cell;
      }
    }

    .active-claim {
      background-color: getNewTokenColor('neutral', '200');
      margin: 12px 0;

      h2 {
        border-bottom: 1px solid getNewTokenColor('neutral', '200');
        padding: 4px;
        margin: 0;
      }
      .section-content {
        padding: 10px;
        background-color: getNewTokenColor('neutral', '200');
        h5 {
          font-size: 1.1em;
        }
        p {
          margin: 0;
        }

        .claim-number {
          font-size: 0.9em;
          font-weight: 600;
        }
        .claim-date {
          padding: 10px 0;
        }
      }
    }
  }

  &#discounts,
  &#risks,
  &#documents {
    p {
      line-height: 1.1em;
    }
  }

  &#claims,
  &#billing #billing-info,
  &#risks,
  &#coverage {
    button {
      width: 100%;
    }
  }

  table {
    margin: 14px 0;
    tbody > tr > td {
      //display: block;
      &.coverage-figures {
        text-align: left;
        > span.caption {
          color: getNewTokenColor('neutral', '200');
          display: block;
        }
      }
    }
    p {
      margin: 0;
      line-height: 1.5em;
    }
  }
}

.policy-large-content {
  padding: 6px 0;

  .section-header {
    font-size: 1.4em;
    color: getNewTokenColor('primary', '800');
    border: 1px solid getNewTokenColor('neutral', '200');
    margin-right: -30px;
    margin-left: -30px;
    margin-bottom: 0.7em;
    padding: 10px 30px;
  }
}

.policy-footer {
  padding-top: 40px;
  white-space: pre-line;
}

// 02 - Large / lg

@media (min-width: 960px) {
  section#coverage table > tbody > tr > td {
    display: table-cell;

    &.coverage-figures {
      text-align: right;
    }
  }
}

// 03 - Tablet / md
@media (min-width: 768px) {
  .policy-large-content {
    min-height: inherit;
  }
}

// 04 - Desktop / lg
@media (min-width: 480px) {
  section#billing .next-bill {
    .left {
      min-width: 240px;
    }
    .actions {
      flex: 2;
      flex-flow: column;
      justify-content: center;
      button {
        margin: 4px;
        min-width: 150px;
      }
    }
  }
}
