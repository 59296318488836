@import './token-colors';
@import 'typography';

//Styles needed for policy details page
.heading {
  font-size: 1.3em;
  font-weight: 600;
  &-policy-vin {
    font-size: 0.9em;
    font-weight: 600;
    color: getNewTokenColor('info', '100');
  }
}

.detailChecklist {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  > ul li {
    display: flex;
    align-items: center;
    span.icon {
      display: flex;
      align-items: center;
      font-size: 1.1em;
      padding-right: 0.25em;
      color: getNewTokenColor('success', '600');
    }
  }

  .list {
    font-weight: 600;
  }
}

.tiles > .column {
  border-bottom: none;
  padding: 10px 10px 80px;
  text-align: left;
}

.row > .column {
  > .tiles *.column {
    padding: 10px;
  }
}

p + ul.checklist {
  margin-top: 12px;
}

ul.checklist {
  flex: 1;

  &.green > li {
    padding-bottom: 16px;
    font-weight: 700;

    i {
      color: getNewTokenColor('success', '400');
    }
  }
}

.policy-card-menu {
  height: 48px;
}

section {
  p.section-secondary-header {
    font-size: 1.3em;
    margin: 0;
    color: getNewTokenColor('neutral', '600');
  }
}

.card {
  border-radius: 4px;
  border: 1px solid getNewTokenColor('neutral', '600');
  background-color: getNewTokenColor('common', 'white');
  text-align: left;

  &-small {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
  }

  &-actions {
    p {
      margin: 0;
    }
    font-size: 0.9em;
    background-color: getNewTokenColor('neutral', '100');
    .row {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      padding: 10px;
      border-top: 1px solid getNewTokenColor('neutral', '200');
      i {
        font-size: 1.1em;
      }

      .buttons {
        display: flex;
        align-items: flex-end;
        flex: 2;
        flex-flow: column;
        justify-content: center;
      }
    }
  }

  .icon-block {
    text-align: center;
    padding: 14px 0;
    order: -1;
    width: 75px;
    float: left;
    border-top-left-radius: 4px;
    font-size: 2em;
    color: getNewTokenColor('common', 'white');
    background-color: getNewTokenColor('primary', '800');
    line-height: 1em;

    .count {
      display: block;
      font-weight: 600;
      line-height: 1.5em;
      font-size: 0.7em;
    }

    .risk {
      display: block;
      line-height: 1em;
      font-size: 0.4em;
      font-weight: 400;
    }
  }

  &-content {
    flex: 1;
    .info-container {
      padding: 10px;
      white-space: normal;
      display: flex;
      flex-flow: row wrap;
    }

    .header {
      flex: 0 100%;

      a.title {
        font-size: 1.4em;
        font-weight: 600;
        color: getNewTokenColor('primary', '800');
      }

      a.detail {
        font-size: 0.85em;
        padding-left: 10px;
        float: right;
        text-align: right;
        flex: 1;
        color: getNewTokenColor('neutral', '600');
        &:active,
        &:focus,
        &:hover {
          color: getNewTokenColor('primary', '800');
          text-decoration: none;
        }
      }
    }

    .info {
      p {
        font-size: 0.9em;
        margin-bottom: 4px;
      }

      .policy-number {
        margin-top: 14px;
        @extend %caption;
      }
    }

    .show-additional {
      float: right;
      text-align: right;
      margin-top: auto;
      flex: 1;

      a {
        color: getNewTokenColor('neutral', '600');
        &:active,
        &:focus,
        &:hover {
          color: getNewTokenColor('primary', '800');
          text-decoration: none;
        }
      }
    }
  }
}

.tempcard {
  height: 250px;
  width: 400px;
  border: 2px solid;
  border-radius: 10px;
}

.policy-section {
  &-details {
    > div {
      box-sizing: border-box;
      padding-left: 0.5em;
      padding-right: 0.5em;

      &.policy-section-details-item {
        padding-left: 0;
        padding-right: 0;

        > div {
          padding: 0 0.5em;
        }
      }
    }
    box-sizing: content-box;

    &-header {
      display: block;
      width: 100%;
      padding-top: 20px;

      &-title {
        font-weight: 600;
      }
    }

    &-item:last-child {
      border-bottom: 0 none;
    }

    &-multiItem {
      display: block;
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px dashed getNewTokenColor('neutral', '200');
      & > span:first-child {
        float: left;
        font-weight: bold;
      }

      & > span.value-display {
        float: right;
        text-align: right;
      }

      & > div.subItemList {
        clear: both;
        padding-bottom: 20px;

        & > div.policy-risks-row {
          clear: both;

          .sub-risk-description {
            font-size: 14px;
            font-weight: 600;
          }

          .sub-risk-value {
            font-size: 14px;
          }

          & > span:last-child {
            float: right;
            text-align: right;
          }

          & > span:first-child {
            float: left;
            padding-left: 15px;
          }
        }
      }
    }

    &-item {
      display: block;
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px dashed getNewTokenColor('neutral', '200');
      .billing-account-number {
        color: getNewTokenColor('primary', '800');
      }

      &:after,
      &:before {
        content: '';
        display: block;
        clear: both;
        width: 0;
      }

      & > span:last-child {
        float: right;
        text-align: right;
      }

      & > span:first-child {
        float: left;
        font-weight: bold;
      }
    }
  }
}
