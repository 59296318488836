///* Variables *///
$displayValues: none, inline, inline-block, block, table, table-column, table-row, table-cell, flex;

///* Functions *///
@function isFirstInMap($mapToCheck, $mapData) {
  //$mapData should contain ($key $value)
  @return nth($mapToCheck, 1) == $mapData; //return boolean for if the arg data is first in the map
}

///* Mixins *///
@mixin breakpointStyles($breakpointPrefix) {
  .#{$breakpointPrefix}fill {
    width: 100%;
  }
  .#{$breakpointPrefix}auto {
    width: auto;
  }

  @each $displayValue in $displayValues {
    .#{$breakpointPrefix}#{$displayValue} {
      display: $displayValue !important;
    }
  }

  @each $positionValue in relative, absolute, fixed {
    .#{$breakpointPrefix}#{$positionValue} {
      position: $positionValue;
    }
  }

  @each $floatValue in none, left, right {
    .#{$breakpointPrefix}float-#{$floatValue} {
      float: $floatValue;
    }
  }
  .#{$breakpointPrefix}float-clear {
    clear: both;
  }
  .#{$breakpointPrefix}float-allow {
    clear: none;
  }

  @each $textAlignValue in left, right, center, justify {
    .#{$breakpointPrefix}text-#{$textAlignValue} {
      text-align: $textAlignValue;
    }
  }

  $defaultPadding: 15;
  $paddingValues: 30, 20, 10, 5, 0;
  $paddingSides: top, right, bottom, left;
  .#{$breakpointPrefix}pad {
    padding: #{$defaultPadding}px;
  }
  @each $paddingSide in $paddingSides {
    .#{$breakpointPrefix}pad-#{$paddingSide} {
      padding-#{$paddingSide}: #{$defaultPadding}px;
    }
  }
  @each $paddingValue in $paddingValues {
    .#{$breakpointPrefix}pad-#{$paddingValue} {
      padding: if($paddingValue == 0, #{$paddingValue}, #{$paddingValue}px);
    }
  }
  @each $paddingValue in $paddingValues {
    @each $paddingSide in $paddingSides {
      .#{$breakpointPrefix}pad-#{$paddingSide}-#{$paddingValue} {
        padding-#{$paddingSide}: if($paddingValue == 0, #{$paddingValue}, #{$paddingValue}px);
      }
    }
  }

  $defaultMargin: 15;
  $marginValues: 30, 20, 10, 5, 0;
  $marginSides: top, right, bottom, left;
  .#{$breakpointPrefix}margin {
    margin: #{$defaultMargin}px;
  }
  @each $marginSide in $marginSides {
    .#{$breakpointPrefix}margin-#{$marginSide} {
      margin-#{$marginSide}: #{$defaultMargin}px;
    }
  }
  @each $marginValue in $marginValues {
    .#{$breakpointPrefix}margin-#{$marginValue} {
      margin: if($marginValue == 0, #{$marginValue}, #{$marginValue}px);
    }
  }
  @each $marginValue in $marginValues {
    @each $marginSide in $marginSides {
      .#{$breakpointPrefix}margin-#{$marginSide}-#{$marginValue} {
        margin-#{$marginSide}: if($marginValue == 0, #{$marginValue}, #{$marginValue}px);
      }
    }
  }
}

///* Styles *///

html {
  height: 100%;
  background-color: getColor('gray-lt');
}

body {
  height: 100%;
  min-width: #{$minBodyWidth}px; //don't respond smaller than 320px
  max-width: #{$maxBodyWidth}px; //don't respond larger than 1920px
  margin: 0 auto; //center beyond the max-width
}

#body-wrapper {
  min-height: 100%;
  background-color: getColor('white');

  & > .column.twelve {
    position: relative;
  }
}

$headerHeight: 70px;
$rooflineHeight: 10px;
header {
  top: 0;
  width: 100%;
  height: $headerHeight + $rooflineHeight;

  @media (max-width: #{map-get($mainBreakpoints,md) - 1}px) {
    height: auto;
  }

  max-width: #{$maxBodyWidth}px;
  color: getColor('brand-blue');
  z-index: 500;

  > header {
    width: inherit;
  }

  .container {
    background-color: getColor('white');
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 5px;
    height: $headerHeight;

    @media (max-width: #{map-get($mainBreakpoints,md) - 1}px) {
      height: 50px;
    }

    @media (min-width: #{map-get($mainBreakpoints,md)}px) {
      padding: 15px 30px 5px;
    }
  }

  .mobile-btn {
    &:nth-of-type(2) {
      order: -1;
    }
    &:nth-of-type(3) {
      order: 1;
    }

    > label {
      font-size: 1.3em;
      font-weight: 600;
      color: getColor('brand-blue');
    }

    > .visible-on-toggle:not(label),
    > .visible-on-toggle.overlay-black {
      position: absolute;
      top: 80px;

      @media (max-width: #{map-get($mainBreakpoints,md) - 1}px) {
        top: 60px;
      }

      left: 0;
      width: 100%;
      z-index: 960;
    }

    > .visible-on-toggle.overlay-black {
      height: 100%;
      width: 100%;
      z-index: 950;
      cursor: default;
    }
  }

  .logo {
    width: 150px;
    @media (max-width: #{map-get($mainBreakpoints,md) - 1}px) {
      width: 130px;
    }
  }

  @at-root #top-roofline {
    width: 100%;
    max-width: #{$maxBodyWidth}px;
    height: $rooflineHeight;
    z-index: 900;
    pointer-events: none; //important to keep dropdown open
    .mover,
    .filler,
    .line {
      background-color: getColor('white');
      border-bottom: 2px solid getColor('brand-red');
    }

    .mover {
      flex-shrink: 0;
      transition: width 0.3s ease-in-out;
    }

    .cutout {
      flex-shrink: 0;
      position: relative;

      img,
      .line {
        height: $rooflineHeight;
        width: 42px;
        transition: all 0.2s ease-in-out;
      }

      img {
        vertical-align: top;
        position: relative;
        z-index: 5;
        opacity: 0;
        transition-delay: 0.1s;
      }

      .line {
        position: absolute;
        top: 0;
        opacity: 1;
        transition-delay: 0s;
      }

      &.visible {
        img {
          opacity: 1;
          transition-delay: 0s;
        }
        .line {
          border-color: getColor('white');
          opacity: 0;
          transition-delay: 0.1s;
        }
      }
    }

    .filler {
      flex-grow: 1;
    }
  }

  @media (min-width: #{map-get($mainBreakpoints,md)}px) {
    position: fixed;

    & + .hero-image {
      margin-top: $headerHeight;
      padding-top: $rooflineHeight;
    }

    & + .aspect-hero-image {
      margin-top: $headerHeight;
    }

    & + .docking-bar + .docking-placeholder {
      margin-top: $headerHeight;
    }

    & + section {
      margin-top: $headerHeight;
      padding-top: 30px !important;
    }

    #top-roofline {
      margin-bottom: -10px;
    }
  }
}

nav {
  a,
  .a {
    &[class*='icon-'] {
      &:before {
        font-size: 1.2em;
        vertical-align: text-top;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.selected {
      color: getColor('gray-dk');
      font-weight: 600;
    }
  }

  @at-root #top-navigation {
    a,
    .a {
      &.selected {
        color: getColor('brand-blue');
      }
    }

    .top {
      > li {
        position: relative;
        > a {
          padding: 1em 5px;
        }

        &:hover,
        &:active {
          .sub {
            display: block;
            visibility: visible;
            opacity: 1;
          }

          .toggle ~ .sub {
            visibility: hidden;
            opacity: 0;
          }

          & ~ li .sub {
            visibility: hidden !important;
            opacity: 0 !important;
          }
        }
      }
    }

    .sub {
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0.1s linear 0.25s,
        opacity 0.1s linear 0.25s;
      position: absolute;
      min-width: 100%;
      padding-top: 28px;
      white-space: nowrap;
      right: 0;

      > li {
        background-color: getColor('white');
        border-left: 1px solid getColor('gray');
        border-right: 1px solid getColor('gray');

        &:first-of-type {
          padding-top: 20px;
          margin-top: -8px;
        }

        > a {
          display: block;
          padding: 12px 40px 12px 30px;

          > [class*='icon-'] {
            font-size: 1.5em;
            color: getColor('gray-dk');
            display: inline-block;
            width: 1em;
            margin-right: 1em;
          }
          &:hover,
          &:focus,
          &:active {
            background-color: getColor('gray-lt');

            & > [class*='icon-'] {
              color: getColor('brand-red');
            }
          }
          &.current {
            & > [class*='icon-'] {
              color: getColor('brand-red');
            }
            color: getColor('gray-dk');
          }
        }

        &:last-of-type {
          padding-bottom: 12px;
          border-bottom: 1px solid getColor('gray');
        }
      }

      &.accordion-radio {
        border: 0;
        max-width: none;

        & > li {
          background-color: getColor('gray-lt');

          &:first-of-type {
            padding-top: 8px;
          }
          &:last-of-type {
            padding-bottom: 0;
          }

          .content {
            white-space: normal;
          }
        }
      }

      &.search-container {
        max-width: none;
        white-space: normal;

        > form {
          margin-top: -8px;
          padding-top: 8px;
        }
      }
    }
  }

  &.sub {
    position: relative;
    padding-bottom: 50px;
    label {
      font-weight: 400;
      color: getColor('brand-blue');
      text-decoration: none;
      font-style: normal;
      font-family: $fontOpenSans;
      padding: 0;
      &:after {
        content: '\edbe';
        font-family: 'icomoon';
      }
    }
    li {
      padding: 0 0 5px;
      & > ul {
        display: none;
      }
      & > input[type='checkbox'] {
        display: none;
        &:checked {
          ~ label {
            &:after {
              content: '\edb6';
              font-family: 'icomoon';
            }
          }
          ~ ul {
            display: block;
            padding: 5px 15px 0 15px;
            li {
              a {
                font-size: 0.85em;
              }
            }
          }
        }
      }
    }

    ul.share {
      position: absolute;
      bottom: 0;
      width: 100%;
      > li:first-of-type {
        padding-left: 0;
      }
      > li:last-of-type {
        padding-right: 0;
      }
    }
  }

  &.mobile {
    li {
      border-bottom: 1px solid getColor('gray');

      label {
        font-weight: 400;
        color: getColor('brand-blue');
        text-decoration: none;
        font-style: normal;
        font-family: $fontOpenSans;
        padding: 0;
        &:after {
          content: '\edbe';
          font-family: 'icomoon';
        }
      }
      a,
      label {
        display: inline-block;
        position: relative;
        padding: 10px 5px;
      }

      & > ul {
        display: none;
      }
      & > input[type='checkbox'] {
        display: none;
        &:checked {
          ~ label {
            &:after {
              content: '\edb6';
              font-family: 'icomoon';
            }
          }
          ~ ul {
            border-top: 1px solid getColor('gray');
            display: block;
            padding: 10px 30px 5px 30px;
            background-color: getColor('gray-lt');
            li {
              padding: 0 0 5px;
              border: 0;
              a {
                padding: 0;
                font-size: 0.85em;
              }
            }
          }
        }
      }
    }
  }

  @at-root #top-mobile-navigation {
    font-size: 1.5em;
    padding-bottom: 50px; //bottom click buffer
    > input {
      display: none;

      &:checked {
        & + .sub {
          position: relative;
          transform: translateX(0);
        }

        & ~ .labels {
          position: absolute;
          transform: translateX(-100%);

          label {
            pointer-events: none; //prevent multiple categories from opening
          }
        }
      }
    }

    .labels {
      position: relative;
      top: 0;
      width: 100%;
      background-color: getColor('white');
      transition: transform 0.3s ease-in-out;

      label {
        display: block;
        position: relative;
        padding: 30px 45px 30px 15px;
        border-bottom: 1px solid getColor('gray');
        font: {
          family: $fontOpenSans;
          style: normal;
          weight: 400;
        }
        color: getColor('brand-blue');

        &:after {
          // TODO: temp commenting out, NEEDS SOLUTION
          // Compound selectors may no longer be extended.
          // @extend .pseudo-icon:after;

          content: '\edb9';
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -0.5em;
        }

        &.current {
          color: getColor('gray-dk');
        }

        @media (max-height: 600px) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    & > .sub {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: getColor('white');
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;

      .heading {
        @extend .bg-blue;
        @extend .pad;
        margin-top: -2px;
        font-size: 1.2em;

        & > label {
          display: inline-block;
          color: getColor('white');
          font: {
            family: $fontOpenSans;
            style: normal;
            weight: 400;
          }
          line-height: 1em;

          .icon-chevron-left {
            font-size: 0.8em;
            margin-left: -0.25em;
            display: inline-block;
            vertical-align: bottom;
            padding-right: 30px;
          }
        }
      }

      & > a {
        display: block;
        position: relative;
        padding: 30px 15px;
        border-bottom: 1px solid getColor('gray');
        font-weight: 400;

        [class*='icon-'] {
          display: inline-block;
          padding-right: 20px;
          color: getColor('gray-dk');
          vertical-align: sub;
          font-size: 1.3em;
        }
        &.current {
          & > [class*='icon-'] {
            color: getColor('brand-red');
          }

          color: getColor('gray-dk');
        }
        &:focus,
        &:active {
          background-color: getColor('gray-lt');
          & > [class*='icon-'] {
            color: getColor('brand-red');
          }
        }
      }

      .accordion-radio {
        font-size: 0.8em;
      }
    }
  }
}

section {
  background-color: #ffffff;
  overflow: hidden;
  clear: both;

  &.main {
    padding: 20px 15px 30px;

    @media (min-width: #{map-get($mainBreakpoints,md)}px) {
      padding: 20px 30px 30px;
    }
  }
}

ds-footer {
  background-color: getColor('gray-lt');
  color: getColor('brand-blue');
  border-top: 2px solid getColor('gray');
  clear: both;

  .heading-sub-section {
    font-weight: 600;
  }

  li {
    padding-bottom: 5px;
  }

  a {
    font-weight: inherit;

    &,
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  hr {
    border-color: getColor('gray');
  }

  .vertical-rule {
    height: 175px;
    border-left: 1px solid getColor('gray');
    margin-left: -1px;
  }

  .heading-sub-section,
  .heading-banner {
    color: inherit;
  }

  ul.share {
    @media (min-width: #{map-get($mainBreakpoints,sm)}px) {
      font-size: 1.5em;
    }
    @media (min-width: #{map-get($mainBreakpoints,md)}px) {
      font-size: 2em;
    }
  }

  #safe-info,
  #web-feedback {
    font: {
      size: 0.9em;
    }
    text-transform: uppercase;
    line-height: 1.2em;
    font-weight: 600;

    [class*='icon-'] {
      font-size: 1.8em;
      margin-right: 3px;
      color: getColor('gray-dk');
    }

    .is-safe {
      font-size: 1.6em;
      line-height: 1.1em;
      font-weight: 600;
    }
  }

  #site-language {
    select {
      color: getColor('brand-blue');
      border: none;
      font-weight: 600;
    }

    > [class*='icon-'] {
      color: getColor('brand-blue');
    }
  }

  .contact-info {
    [class*='icon-'] {
      color: getColor('gray-dk');
    }
  }

  .my-amfam-app {
    font-size: 1.5em;
    color: getColor('heading-gray');
    margin-bottom: 0;
  }

  img {
    &.logo {
      max-width: 150px;
    }

    &.app-icon {
      max-width: 35px;
      max-height: 35px;
      width: 100%;
    }

    &.app-logo {
      max-width: 150px;
      width: 100%;
    }

    &.app-link {
      max-width: 100px;
      width: 100%;
    }
  }

  .type-treatment {
    .accent {
      font-size: 1.3em;
    }
  }
}

@each $breakpointKey, $breakpointWidth in $mainBreakpoints {
  //loop through and add breakpointStyles for each breakpoint
  @if (isFirstInMap(map-keys($mainBreakpoints), $breakpointKey)) {
    @include breakpointStyles(null); //don't wrap the first (default) style set in a media query
  } @else {
    @media (min-width: #{$breakpointWidth}px) {
      @include breakpointStyles($breakpointKey + '-');
    }
  }
}
