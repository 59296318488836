@import './token-colors';
@import 'elements/modals'; // TODO - Remove dependency on DSG

.modal {
  .content {
    background-color: getNewTokenColor('common', 'white');
    position: relative;
  }
}

.ds-modal {
  #payment-method-edit-detailrow {
    border: none !important;
    padding: 0 !important;
  }
}

.modal.normal {
  .icon-exit.close {
    position: absolute;
    top: 30px;
    right: 30px;
    float: none;
    color: getNewTokenColor('neutral', '600');
  }
}

.modal.full-screen {
  .icon-exit.close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
  .content {
    width: 100%;
    margin: 15px;
  }
}

body.modal-open {
  #body-wrapper {
    filter: none;
  }
}

body.modal-open {
  .modal {
    &.ds-modal {
      z-index: -7000;
      opacity: 0;
      transition: none;
    }

    &.ds-modal.open {
      transition: opacity 0.2s ease-in-out, z-index 0s linear 0s;
      z-index: 7000;
      opacity: 1;
      .ds-table-body-cell-header-wrapper .table-body-cell-label-content,
      .payment-method-edit-title {
        padding: 0;
        display: none;
      }

      .ds-table-body-cell-header-wrapper {
        .ds-table-body-cell-header {
          display: block;
          background-color: transparent;
          padding: 0;
        }
      }
    }
  }
}
