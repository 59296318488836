@font-face {
  font-family: 'Oswald';
  src:
    url('~@amfament/ds-webassets-fonts/oswald/files/oswald-v16-latin-ext-700.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/oswald/files/oswald-v16-latin-ext-700.woff2?20220127')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-300.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-300.woff2?20220127')
      format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-300-italic.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-300-italic.woff2?20220127')
      format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-400.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-400.woff2?20220127')
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-400-italic.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-400-italic.woff2?20220127')
      format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-600.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-600.woff2?20220127')
      format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-600-italic.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-600-italic.woff2?20220127')
      format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-700.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-700.woff2?20220127')
      format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-700-italic.woff?20220127')
      format('woff'),
    url('~@amfament/ds-webassets-fonts/open-sans/files/open-sans-v13-latin-ext-700-italic.woff2?20220127')
      format('woff2');
  font-weight: 700;
  font-style: italic;
}
