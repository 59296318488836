@import 'token-colors';

$footer-height: 55px;
$width: 500px;
$img-width: 215px;

.poi-card {
  border: 1px solid getNewTokenColor('neutral', '600');
  position: relative;
  border-radius: 20px;
  width: $width;
  background-color: getNewTokenColor('common', 'white');
  font-family: Arial, Helvetica, sans-serif;
  margin: 50px; //Temporary

  &-content {
    &-block {
      margin-bottom: 12px;

      &-left {
        float: left;
        margin-right: 30px;
      }

      &-right {
        white-space: nowrap;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-left {
    width: 90px;
    padding-top: 10px;
    padding-left: 10px;
  }

  &-top {
    border-bottom: 1px solid getNewTokenColor('neutral', '200');
    margin-bottom: $footer-height;
  }

  &-right {
    background-color: getNewTokenColor('neutral', '200');
    border-radius: 0 20px 0 0;
    height: 100%;
    font-size: 11px;
    padding: 10px;
  }

  &-header {
    margin-bottom: 8px;
    font-weight: 700;
    height: 25px;
  }

  &-title {
    font-size: 16px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }

  &-flip-button {
    position: absolute;
    right: 10px;
    top: 5px;
  }
}

.fixed-bottom {
  height: $footer-height;
  position: absolute;
  bottom: 0;
  width: $width;
  left: 0;

  & > span {
    margin: 10px;
    font-size: 10px;
    width: 68%;
    display: block;
    float: left;
  }
}

img.sideways-logo {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: translateY($img-width - 5px) rotate(-90deg);
  transform-origin: top left;
  width: $img-width;
  max-width: $img-width;
}
