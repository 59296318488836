@import 'token-colors';
@import 'typography';
@import 'icons'; // TODO - Figure out of this is needed
@import 'elements/lists'; // TODO - Remove dependency on DSG

ul.contact-us li {
  padding: 8px 0;
  text-align: left;
  a {
    font-weight: 400;
  }
  span.icon {
    font-size: 1.5em;
    color: getNewTokenColor('neutral', '600');
    display: inline-block;
    width: 1em;
    margin-right: 1em;
  }
}

ul.error-list {
  @extend %caption;
  color: getNewTokenColor('error', '600');
  > li:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 8px;
    background: getNewTokenColor('error', '600');
  }
}

ul.criteria-list {
  @extend %caption;
  > li {
    color: getNewTokenColor('neutral', '800');
    line-height: inherit;
    white-space: nowrap;
    &:before {
      opacity: 100;
      color: getNewTokenColor('neutral', '600');
      content: '\e91b';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 8px;
      background: transparent;
    }
    &.isAvailable {
      color: getNewTokenColor('neutral', '800');
      &:before {
        opacity: 100;
        color: getNewTokenColor('success', '400');
      }
    }
    &.notAvailable,
    &.hasError {
      color: getNewTokenColor('error', '600');
      &:before {
        opacity: 100;
        color: getNewTokenColor('error', '600');
        content: '\e91b'; // icon-exit
      }
    }
    &.isValid {
      &:before {
        opacity: 100;
        color: getNewTokenColor('success', '400');
        content: '\e932'; //icon-complete-circle
      }
    }
  }
}

/* Display checkmark icons as bullets */
ul.complete-list {
  > li {
    line-height: inherit;
    white-space: nowrap;
    &:before {
      font-family: $fontIcons;
      content: '-';
      display: inline-block;
      width: 12px;
      height: 16px;
      margin-right: 8px;
      border-radius: 8px;
      background: transparent;
      font-size: 1.5em;
    }
  }
}

/* Modifiers - Always list last */

ul {
  &.-condensed li {
    margin-bottom: 0.6em;
  }
  &.-superCondensed li {
    margin-bottom: 0.3em;
  }
  &.-flush li {
    margin-bottom: 0;
  }
}


ul.disc-list{
  list-style-type: disc;
  list-style: disc;
  list-style-position: inside;
  li{
    list-style: disc;
    list-style-type: disc;  
    list-style-position: inside;
  }
}
