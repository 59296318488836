@import 'token-colors';
@import 'fonts';

$fontFallback: sans-serif;

$fontOpenSans: 'Open Sans', $fontFallback;
$fontOswald: 'Oswald', $fontFallback;
$fontGeorgia: 'Georgia', $fontFallback;

body {
  font-family: $fontOpenSans;
  font-weight: 400;
  color: getNewTokenColor('neutral', '800');
}

$headings: (
  '' 'banner' $fontOswald 2.5em 700,
  'h1' 'main' $fontOswald 2em 700,
  'h2' 'sub-page' $fontOpenSans 1.5em 400,
  'h3' 'section' $fontOpenSans 1.3em 400,
  'h4' 'sub-section' $fontOpenSans 1.3em 400
);

@mixin headingStyles($heading) {
  font: {
    family: nth($heading, 3);
    size: nth($heading, 4);
    weight: nth($heading, 5);
    style: normal;
  }

  line-height: 1.25em;
  margin-bottom: nth($heading, 4) * 0.25;
  color: getNewTokenColor('neutral', '600');
}

@each $heading in $headings {
  @if nth($heading, 1) != '' {
    #{nth($heading, 1)} {
      @include headingStyles($heading);
    }
  }

  .heading-#{nth($heading, 2)} {
    display: block;
    @include headingStyles($heading);

    @if nth($heading, 2) == 'banner' {
      text-transform: uppercase;
    }
  }
}

p {
  margin: 0 0 0.2em;
}

a,
.a {
  font: {
    family: inherit;
    style: inherit;
    weight: 600;
  }
  color: getNewTokenColor('primary', '800');
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: getNewTokenColor('primary', '800');
    text-decoration: underline;
  }

  &.underline {
    &:hover,
    &:focus {
      text-decoration: underline !important;
    }
  }

  &.no-underline {
    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }

  &.accent {
    font-weight: 400;
  }
}

.accent {
  font-family: $fontGeorgia;
  font-style: italic;
  font-weight: 400;

  a {
    font-weight: 400;
  }
}

sup {
  top: -0.25em;
}

.color {
  &-copy {
    color: getNewTokenColor('neutral', '800') !important;
  }

  &-heading {
    color: getNewTokenColor('neutral', '600') !important;
  }

  &-light-gray {
    color: getNewTokenColor('neutral', '100') !important;
  }

  &-gray {
    color: getNewTokenColor('neutral', '200') !important;
  }

  &-dark-gray {
    color: getNewTokenColor('neutral', '600') !important;
  }

  &-white {
    color: getNewTokenColor('common', 'white') !important;
  }

  &-blue {
    color: getNewTokenColor('primary', '800') !important;
  }

  &-red {
    color: getNewTokenColor('secondary', '600') !important;
  }

  &-inherit {
    color: inherit;
  }
}
