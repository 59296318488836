.overlay- {
  &white {
    background-color: rgba(getColor('white'), 0.9) !important;
    color: getColor('heading-gray');
  }

  &light-gray {
    background-color: rgba(getColor('gray-lt'), 0.9) !important;
    color: getColor('heading-gray');

    .heading-banner {
      color: getColor('brand-blue');
    }
  }

  &black {
    background-color: rgba(getColor('black'), 0.7) !important;
    color: getColor('white');
  }

  &blue {
    background-color: rgba(getColor('brand-blue'), 0.88) !important;
    color: getColor('white');
  }

  &red {
    background-color: rgba(getColor('brand-red'), 0.92) !important;
    color: getColor('white');
  }
}
