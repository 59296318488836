@import '@amfament/ds-tokens-amfam-web-scss/token-color-map-deep.scss'; // $token-color-map

// Account for the first prop being 'color'
$token-color-map: map-get($token-color-map, 'color');

@function mapDeepGet($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@function getNewTokenColor($keys...) {
  $value: $token-color-map;

  @each $key in $keys {
    @each $listKey in $key {
      $value: map-get($value, $listKey);
      @if ($value == null) {
        // If value doesn't map trigger debug
        @debug $listKey;
      }
    }
  }
  @return $value;
}

@mixin background-color-class-mixin() {
  @each $mapname, $mapvalue in $token-color-map {
    @if type-of($mapvalue) == 'map' {
      @each $name, $color in $mapvalue {
        .#{$mapname}-#{$name}-bg {
          background-color: $color !important;
        }
      }
    }
  }
}

@mixin text-color-class-mixin() {
  @each $mapname, $mapvalue in $token-color-map {
    @if type-of($mapvalue) == 'map' {
      @each $name, $color in $mapvalue {
        .#{$mapname}-#{$name}-text {
          color: $color !important;
        }
      }
    }
  }
}
