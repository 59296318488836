.tooltip__container {
  background-color: #fff;
  border-radius: 5px;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.3));
  padding: 15px;
  color: #000;
  font-weight: 600;
}

.cdk-overlay-pane {
  &::after {
    content: '';
    position: absolute;
    margin-left: -5px;
    border: 10px solid transparent;
    border-top-color: #fff;
  }

  &.top-center__bottom-start {
    &::after {
      top: 100%;
      left: 15px;
    }
  }

  &.top-center__bottom-end {
    &::after {
      top: 100%;
      right: 15px;
    }
  }

  &.bottom-center__top-start {
    &::after {
      bottom: 100%;
      left: 15px;
      border-bottom-color: #fff;
      border-top-color: transparent;
    }
  }

  &.bottom-center__top-end {
    &::after {
      bottom: 100%;
      border-bottom-color: #fff;
      border-top-color: transparent;
      right: 15px;
    }
  }
}
