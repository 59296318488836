/* Reset - MUST BE THE FIRST IMPORT */
@import 'core/reset'; // aliased from @amfam/shared/ui/digital-services-styles lib

@import './flex';
@import './helpers';
@import 'token-colors';
@import 'typography'; // aliased from @amfam/shared/ui/digital-services-styles lib
@import './icons';
@import './banners';
@import 'borders'; // aliased from @amfam/shared/ui/digital-services-styles lib
@import './lists';
@import 'forms'; // aliased from @amfam/shared/ui/digital-services-styles lib
@import 'button-groups'; // aliased from @amfam/shared/ui/digital-services-styles lib
@import 'elements/loadingSpinner'; // aliased from @amfam/shared/ui/digital-services-styles lib
@import './swiper';
@import './tables';
@import './tiles';
@import './modals';
@import './tooltip';

/* Init class mixins */
@include init-icon-classes;
@include init-border-colors;
@include init-typography-classes;
@include init-flex-classes;

@include background-color-class-mixin();
@include text-color-class-mixin();

/* Core */
@import './core';
@import 'breakpoints'; // aliased from @amfam/shared/ui/digital-services-styles lib

/* Accessibility */
@import 'accessibility'; // aliased from @amfam/shared/ui/digital-services-styles lib

/* Layout */
@import 'layout/grid'; // aliased from @amfam/shared/ui/digital-services-styles lib

@import './digitalstyleguide/style/colors'; // TODO - Remove when the dependent DSG items below are removed
@import './digitalstyleguide/style/backgrounds';
@import './digitalstyleguide/style/overlays';

/* Elements */
@import './digitalstyleguide/elements/tiles';
@import './digitalstyleguide/style/stripes';

/* ===================================
  Main Wrapper Set Up
=================================== */

html {
  background-color: getNewTokenColor('neutral', '100');
}

body {
  margin-top: 0;
  max-width: #{$maxBodyWidth}px;
  min-width: #{$minBodyWidth}px;
  width: 100%;
  text-size-adjust: none; // fix for tablet text re-sizing
}

header,
#header {
  @extend header;
  max-width: #{$maxBodyWidth}px;
  color: getNewTokenColor('primary', '800');
}

#body-wrapper {
  background-color: transparent;
  & > .column.twelve {
    min-height: 100vh;
    height: 100%;
  }
}

div.main {
  padding: 20px 15px 30px;

  div.main {
    padding: 20px 0 0 0;
  }

  @media (min-width: #{map-get($mainBreakpoints,md)}px) {
    padding: 20px 30px 30px;
  }
}

.grey-background {
  background-color: getNewTokenColor('neutral', '50');
}

.align-center {
  display: flex;
  justify-content: center;
}

div.callToAction {
  line-height: 36px;
  padding-top: 64px;

  @media (max-width: #{map-get($mainBreakpoints,md)}px) {
    button {
      float: left;
    }
  }

  // Swap primary and secondary cta order between mobile and desktop
  &-row {
    display: block;
    float: inherit;
    & .primary-cta {
      margin: 0 6px 0 0;
    }
    & .secondary-cta {
      margin: 0;
    }
    @media (min-width: #{map-get($mainBreakpoints,md)}px) {
      float: right;
      display: flex;
      & .primary-cta {
        order: 1;
        margin: 0;
      }
      & .secondary-cta {
        order: 0;
        margin: 0 6px 0 0;
      }
    }
  }
}

#app-wrapper {
  background-color: getNewTokenColor('common', 'white');
  position: relative; /* Mobile first */
  margin-bottom: 0;
  padding: 0;
}

.app-header {
  background: getNewTokenColor('common', 'white');
}

footer {
  padding-top: 60px;
  background-color: getNewTokenColor('common', 'white');
}

/* loading spinner ( used before app bootstrap) */
.loading-container {
  max-width: #{$maxBodyWidth}px;
}

/* ===================================
  Global Alignment Helpers
=================================== */
.float-clear-all {
  clear: both;
  float: none;

  &:before,
  &:after {
    content: '';
    display: block;
    clear: both;
    width: 0;
  }
}

/* ===================================
  Global Misc Helpers
=================================== */
.pointer {
  cursor: pointer;
}

hr {
  &.dashed {
    border-top: 1px dashed getNewTokenColor('neutral', '200');
  }
}

/* ===================================
  Global Aside / Sidebar Set Up
=================================== */
// TODO - move to sidebar component (only used there)
.aside-wrapper {
  @media (min-width: #{map-get($mainBreakpoints,lg)}px) {
    width: 340px !important;
    flex-shrink: 0;
    max-width: 100%;
  }
}
aside.main-aside {
  padding: 0 15px 30px;

  @media (min-width: #{map-get($mainBreakpoints,lg)}px) {
    border-left: 1px solid getNewTokenColor('neutral', '100');
    margin: 15px 0 0 0;
  }

  @media (min-width: #{map-get($mainBreakpoints,md)}px) {
    padding: 0 30px 15px;
  }
}

/* ===================================
Global Link Helpers
=================================== */
a,
.a {
  // TODO remove this @extend
  @extend a;
  font-weight: 400;
}

.max-fill {
  max-width: 100%;
}

/* ===================================
Global Misc Container Set Up
=================================== */
#toast-container {
  position: fixed !important;
  top: 90px !important;
  right: 15px !important;
  font-size: inherit !important;
  opacity: 1;
  display: block !important;
}

banner {
  display: block;
}

/* ===================================
Global Content Set Up
=================================== */

// styleguide override for tiles
// The styleguide applies 10px of padding to all .columns inside of .tiles
.row > .column > .tiles *.column {
  padding: 0;
}

// Reset to only apply to .column's after a class="row tiles" element
.row.tiles > .column {
  padding: 10px;
}

/* ==================================
Print Styles
================================== */
@media print {
  button {
    display: none;
  }

  .no-print {
    display: none;
  }

  html,
  body {
    height: auto;
    min-height: unset;
  }

  body {
    margin: 0;
  }

  #body-wrapper {
    height: auto;
    min-height: unset;
  }
}

// Changes margins when printing a document
@page {
  margin: 0.75in;
}

.capitalize {
  text-transform: capitalize;
  &[ds-button] {
    text-transform: capitalize;
    font-family: $fontOpenSans;
  }
}

.text-center {
  text-align: center;
}

.icon-gradient {
  &::before {
    color: transparent;
    background: linear-gradient(180deg, #4f90ff 22.4%, #003da5 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
}
