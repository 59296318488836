ol, ul {
  list-style-type: none;

  &.bullet,
  &.number {
    padding: 0 1em 1em;
    margin-left: 1em;

    & li {
      margin-bottom: 1em;
    }

    ul, ol {
      padding: 1em 1em 0;
      margin-left: 1em;
    }
  }

  &.bullet {
    li {
      list-style-type: disc;

      li {
        list-style-type: circle;

        li {
          list-style-type: square;
        }
      }
    }
  }

  &.number {
      li {
        list-style-type: decimal;

        li {
          list-style-type: lower-alpha;

          li{
            list-style-type: lower-roman;
          }
        }
      }
  }

  &.comma {
    display: inline;

    > li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 5px;

        &:after {
          content: ',';
        }
      }
    }
  }

  &.in-line {
    & > li {
      display: inline-block;
    }
  }
}

.rich-text {
  ol {
    @extend .number;
  }

  ul {
    @extend .bullet;
  }
}
